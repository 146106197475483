import React from 'react'
import HintTooltip from './HintTooltip' // Adjust import as needed
import { makeStyles } from '@material-ui/styles'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import Text from 'pf9-ui-components/built/elements/Text'
import QuantitySelector from 'pf9-ui-components/built/components/QuantitySelector'

const useStyles = makeStyles<Theme>((theme) => ({
  expandedContainer: {
    display: 'grid',
    gap: theme.spacing(1),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

type QuantitySelectorWithHeaderProps = {
  id: string
  value: number
  onChange: (value: number) => void
  min: number
  max: number
  info?: string
  align?: any
  offset?: any
  label: string
}

const QuantitySelectorWithHeader: React.FC<QuantitySelectorWithHeaderProps> = ({
  id,
  value,
  onChange,
  min,
  max,
  info,
  align,
  offset,
  label,
}) => {
  const classes = useStyles()

  const handleChange = (newValue) => {
    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <div className={classes.expandedContainer}>
      <div className={classes.header}>
        <Text
          variant="caption1"
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {label}
        </Text>
        {info && <HintTooltip message={info} align={align} offset={offset} />}
      </div>
      <QuantitySelector id={id} onChange={handleChange} value={value} min={min} max={max} />
    </div>
  )
}

export default QuantitySelectorWithHeader
