import { makeStyles } from '@material-ui/styles'
import Text from 'pf9-ui-components/built/elements/Text'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React from 'react'

interface EmptyContentProps {
  title: string
  subTitle?: string
  width?: number
}

interface StyleProps {
  width?: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  content: ({ width }) => ({
    width: width ? `${width}px` : 'auto',
    textAlign: 'center',
  }),
  headerText: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  subEmptyContent: {
    color: theme.palette.grey[500],
    fontWeight: theme.typography.body1.fontWeight,
  },
}))

const EmptyContent = ({ title, subTitle, width }: EmptyContentProps) => {
  const classes = useStyles({ width })

  return (
    <div className={classes.content}>
      <Text
        className={classes.headerText}
        variant="subtitle2"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        {title}
      </Text>
      {subTitle && (
        <Text
          className={classes.subEmptyContent}
          variant="subtitle2"
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {subTitle}
        </Text>
      )}
    </div>
  )
}

export default EmptyContent
