import store from 'app/store'
import { ClusterParams } from 'app/plugins/infrastructure/components/common/model'
import { isNilOrEmpty, ensureArray } from 'utils/fp'
import { allKey } from 'app/constants'
import { kaapiClustersSelector } from 'k8s/components/kaapi/clusters/selectors'

export default function parseClusterIdsFromParams<P extends ClusterParams>(params: P): string[] {
  const state = store.getState()
  const { clusterId } = params
  if (isNilOrEmpty(clusterId) || clusterId === allKey) {
    const allClusters: any[] = kaapiClustersSelector(state, params)
    return allClusters.map((cluster) => cluster.uuid)
  }
  return ensureArray(clusterId)
}
