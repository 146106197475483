import { makeStyles } from '@material-ui/styles'
import { AppPlugins } from 'app/constants'
import clsx from 'clsx'
import Text from 'core/elements/Text'
import { PluginSection } from 'core/plugins/model'
import Theme from 'core/themes/model'
import React, { useEffect, useRef } from 'react'
import PluginCard from './PluginCard'
import SpinLogo from './SpinLogo'
import usePluginRouter from 'core/hooks/usePluginRouter'

const PLUGIN_COLOR_MAP = {
  [AppPlugins.OpenStack]: '#3ACCC533',
  [AppPlugins.Kubernetes]: '#326DE633',
}

interface PluginMenuProps {
  open: boolean
  toggleMenu: () => void
  currentPluginId: string
  options: PluginSection[]
  onPluginChange?: (pluginId: string) => void
  collapse?: boolean
}

export default function PluginMenu({
  open,
  toggleMenu,
  onPluginChange,
  collapse = false,
  options,
}: PluginMenuProps) {
  const { currentPluginId } = usePluginRouter()
  const isAccountPlugin = currentPluginId === AppPlugins.MyAccount
  const classes = useStyles({ open, collapse, isAccountPlugin })

  // When users click away from the plugins drop down it should close
  const divRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node) && open) {
        toggleMenu()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  return (
    <div className={classes.pluginMenuContainer} ref={divRef}>
      <div className={clsx(classes.anchor, { active: open })}>
        <SpinLogo active={false} />
        <Text variant="subtitle1" className={classes.productTitle}>
          {'Private Cloud Director'}
        </Text>
      </div>
      {open && (
        <div className={classes.menu}>
          {options?.map((option) => {
            if (option.id === AppPlugins.MyAccount) return null
            return (
              <PluginCard
                key={option.id}
                title={option.name}
                description={option.description}
                onClick={() => onPluginChange(option.id)}
                icon={option.icon}
                active={false}
                color={PLUGIN_COLOR_MAP[option.id]}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles<
  Theme,
  { open: boolean; collapse?: boolean; isAccountPlugin?: boolean }
>((theme) => ({
  pluginMenuContainer: {
    gridArea: 'nav-header',
    '& .menu-popover': {
      padding: 8,
      backgroundColor: theme.components.sidebar.background,
      borderColor: theme.components.sidebar.border,
    },
    borderBottom: ({ open }) => (open ? 'none' : `1px solid ${theme.components.sidebar.border}`),
    marginLeft: ({ isAccountPlugin }) => (isAccountPlugin ? '-72px' : 0),
  },
  anchor: {
    display: 'grid',
    gridTemplateColumns: ({ collapse }) => (collapse ? 'auto' : 'max-content 1fr max-content'),
    gridGap: theme.spacing(2),
    alignItems: 'center',
    justifyItems: 'stretch',
    paddingLeft: theme.spacing(2),
    height: 64,
    '&.active': {
      background: theme.components.card.background,
    },
    '& #logoDefault': {
      justifySelf: 'center',
    },
  },
  productTitle: {
    color: theme.components.sidebar.activeText,
    textTransform: 'capitalize',
    display: ({ collapse }) => (collapse ? 'none' : 'unset'),
    whiteSpace: 'nowrap',
    fontSize: 18,
  },
  chevronIcon: {
    position: 'absolute',
    left: '256px',
    display: ({ collapse }) => (collapse ? 'none' : 'unset'),
    transform: ({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)'),
    color: theme.components.sidebar.activeText,
  },
  menuContainer: {
    '& .menu-popover': {
      padding: '8px',
      top: 'unset',
      left: 'unset',
    },
  },
  menu: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(2),
    zIndex: 100,
    position: 'absolute',
    padding: theme.spacing(3),
    backgroundColor: theme.components.card.background,
  },
}))
