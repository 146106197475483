import React from 'react'
import Bugsnag from 'utils/bugsnag'

// A simple error boundary class to catch an error for airgapped systems.
// TODO, utilize a componentDidCatch when there is a backend api to report
// issues, assuming we build support for it.

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }

    // @ts-ignore
    return this.props.children
  }
}
export const getErrorBoundary = () => {
  const bsnagPlugin = Bugsnag.getPlugin('react')
  return bsnagPlugin ? bsnagPlugin.createErrorBoundary(React) : ErrorBoundary
}
