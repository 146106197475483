import React, { FC } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

interface CardViewContainerProps<T> {
  data: any[]
  CardComponent: FC<{ item: T }>
  uniqueIdentifier: keyof T
}

const CardViewContainer = <T extends Record<string, any>>({
  data,
  CardComponent,
  uniqueIdentifier,
}: CardViewContainerProps<T>) => {
  const classes = useStyles()

  return (
    <div className={classes.cardsContainer}>
      {data?.map(({ item }) => (
        <CardComponent key={String(item[uniqueIdentifier])} item={item} />
      ))}
    </div>
  )
}

export default CardViewContainer

const useStyles = makeStyles<Theme>((theme) => ({
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
  },
}))
