import { makeStyles } from '@material-ui/styles'
import { pathStrOr } from 'app/utils/fp'
import clsx from 'clsx'
import { clientActions, ClientState, clientStoreKey } from 'core/client/clientReducers'
import Text from 'core/elements/Text'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useToggler from 'core/hooks/useToggler'
import { matchesCurrentPath } from 'core/plugins/route-helpers'
import FrameContext from 'core/providers/frame-provider'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import { partition, prop } from 'ramda'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/tracking'
import NavItem from './NavItem'
import NavPane from './NavPane'
import NavSection from './NavSection'
import PluginMenu from './PluginMenu'
import useListAction from 'core/hooks/useListAction'
import { listKaapiClusters } from 'k8s/components/kaapi/clusters/actions'
import { kaapiClustersSelector } from 'k8s/components/kaapi/clusters/selectors'
import IconButton from 'core/elements/button/IconButton'
import TrustCAAlert from 'k8s/components/TrustCAAlert'
import { getClusterEndpoint } from 'k8s/components/dashboard/helpers'
import { AppPlugins } from 'app/constants'
import OpenStackMainMenu from './OpenStackMainMenu'

interface Props {
  setPluginId: (id: string) => void
}

const sidebarPaneRef = React.createRef<HTMLDivElement>()
const Sidebar: FC<Props> = ({ setPluginId }) => {
  const dispatch = useDispatch()
  const [menuOpen, toggleMenuOpen] = useToggler(false)
  const { features = {} } = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { frame: { sidebarState = 'expanded', sidebarPane = 'default' } = {} } = useSelector(
    prop<string, ClientState>(clientStoreKey),
  )
  const drawerOpen = sidebarState === 'expanded'
  const version = pathStrOr('4', 'releaseVersion', features)
  const { sections, currentSection, currentPath, currentPluginId } = usePluginRouter()
  const [currentSectionBottomLinks, currentSectionLinks] = useMemo(
    () => partition((link) => link.isBottomLink, currentSection.links),
    [currentSection],
  )
  const isAccountPlugin = currentPluginId === AppPlugins.MyAccount
  const navWidth = isAccountPlugin ? 216 : 288

  const classes = useStyles({ drawerOpen, menuOpen, navWidth })

  const toggleDrawer = useCallback(() => {
    dispatch(clientActions.setSidebarState(sidebarState === 'expanded' ? 'collapsed' : 'expanded'))
  }, [sidebarState])

  const { prefs: k8sGlobalPrefs, updatePrefs } = useScopedPreferences('k8sPluginGlobalParams')
  useListAction(listKaapiClusters)
  const clusterList = useSelector(kaapiClustersSelector)

  // Update the preference store with the first cluster
  useEffect(() => {
    if (!clusterList?.length || k8sGlobalPrefs?.cluster) return

    updatePrefs({
      cluster: clusterList[0]?.metadata?.name,
      cluster_endpoint: getClusterEndpoint(clusterList[0]?.spec?.controlPlaneEndpoint?.host),
      clusterId: clusterList[0]?.metadata?.uid,
    })
  }, [clusterList, k8sGlobalPrefs])

  const { setFrameContainerRef } = React.useContext(FrameContext)
  useEffect(() => {
    setFrameContainerRef({
      sidebarPaneContainer: sidebarPaneRef.current,
    })
  }, [])

  const handlePluginChange = useCallback(
    (newPluginId) => {
      setPluginId(newPluginId)
      trackEvent('Changed Application Plugin', {
        application: newPluginId,
      })
      toggleMenuOpen()
    },
    [setPluginId],
  )
  const navMenuOffset = useMemo(
    () => ({
      vertical: -48,
      horizontal: 8,
    }),
    [drawerOpen],
  )

  const FooterContent = useMemo(
    () =>
      drawerOpen ? (
        <Text variant="body2" component="h6" className={classes.version}>
          Version: {version}
        </Text>
      ) : (
        currentSectionBottomLinks.map((bLink, idx) => (
          <NavItem
            {...bLink}
            key={idx}
            isActive={matchesCurrentPath(currentPath, bLink.link)}
            drawerOpen={drawerOpen}
            // tooltip
            tooltipProps={{ className: classes.navText }}
          />
        ))
      ),
    [version, drawerOpen, currentPath, currentSectionBottomLinks],
  )

  const renderNavItems = useCallback(() => {
    return currentSectionLinks.map((navItem, idx) => {
      return (
        <NavSection
          {...navItem}
          key={`${navItem?.name}-${idx}`}
          isActive={matchesCurrentPath(currentPath, navItem)}
          drawerOpen={drawerOpen}
          menuOffset={navMenuOffset}
          k8sClusterAvailable={!!clusterList?.length}
        />
      )
    })
  }, [currentSectionLinks, currentPath, drawerOpen, navMenuOffset, clusterList, currentPluginId])

  return (
    <>
      <TrustCAAlert />
      <aside
        className={clsx(classes.customNav, {
          [classes.customNavWidth]: sidebarPane === 'custom',
          [classes.openPane]: sidebarPane === 'custom',
          [classes.hiddenPane]: sidebarPane !== 'custom',
        })}
      >
        <div className="sidebarPanePortal" ref={sidebarPaneRef} />
      </aside>
      {/* Show main menu as collapsed on Settings page */}
      {isAccountPlugin && <OpenStackMainMenu />}
      <aside
        className={clsx(classes.nav, {
          [classes.customNav]: isAccountPlugin,
          [classes.openPane]: sidebarPane === 'default',
          [classes.hiddenPane]: sidebarPane !== 'default',
        })}
      >
        <PluginMenu
          open={menuOpen}
          toggleMenu={toggleMenuOpen}
          collapse={!drawerOpen}
          options={sections}
          onPluginChange={handlePluginChange}
          currentPluginId={currentPluginId}
        />
        {sidebarPane !== 'custom' && (
          <IconButton
            className={classes.toggleCaret}
            onClick={toggleDrawer}
            icon={drawerOpen ? 'chevron-left' : 'chevron-right'}
            size="md"
            solid
          />
        )}

        <NavPane className={clsx(classes.primaryPane, 'thin-scrollbar')}>
          {isAccountPlugin && drawerOpen && (
            <Text variant="subtitle2" className={classes.navPaneTitle}>
              Account Settings
            </Text>
          )}
          {renderNavItems()}
        </NavPane>
        {FooterContent}
      </aside>
    </>
  )
}

interface StyleProps {
  drawerOpen: boolean
  menuOpen: boolean
  navWidth: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  hiddenPane: {
    transform: ({ drawerOpen }) => `translate(-${drawerOpen ? '289' : '72'}px, 0px)`,
  },
  openPane: {
    transform: ({ drawerOpen }) => `translate(0px, 0px)`,
    backgroundColor: theme.components.sidebar.background,
  },
  customNav: {
    marginLeft: 72,
  },
  customNavWidth: {
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '100%',
      bottom: 0,
      width: 128,
      backgroundColor: theme.components.sidebar.background,
    },

    '& ~section.content-main': {
      zIndex: 1000,
      padding: '80px 32px',
    },
  },
  navPaneTitle: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  nav: {
    position: 'relative',
    width: ({ drawerOpen, navWidth }) => (drawerOpen ? navWidth : 72),
    zIndex: 99,
    height: '100vh',
    borderRight: `1px solid ${theme.components.sidebar.border}`,
    backgroundColor: theme.components.sidebar.background,
    display: 'grid',
    justifyContent: 'stretch',
    transition: 'width .2s ease',
    gridTemplateRows: 'max-content 1fr',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `"nav-header" "nav-primary-pane"`,
  },
  toggleCaret: {
    backgroundColor: theme.palette.grey['000'],
    // this box shadow a bit intense with the lighter theme
    // boxShadow: '0 4px 24px 0 rgba(37, 37, 63, 0.25)',
    position: 'absolute',
    right: 0,
    top: 64 - 24 / 2,
    width: 24,
    height: 24,
    borderRadius: 24,
    border: 'none',
    transform: 'translate(50%, 0)',
    color: theme.palette.grey[900],
    outline: '0 !important',
    zIndex: 99,
    alignItems: 'center',
    justifyItems: 'center',
    display: ({ menuOpen }) => (menuOpen ? 'none' : 'grid'),
    '&:hover': {
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.grey[100],
    },
    '& i': {
      fontSize: 12,
      width: 'auto',
    },
  },
  primaryPane: {
    width: ({ drawerOpen, navWidth }) => (drawerOpen ? navWidth : 72),
    zIndex: 90,
    gridArea: 'nav-primary-pane',
    maxHeight: '100vh',
    overflowY: ({ drawerOpen }) => (drawerOpen ? 'auto' : 'visible'),
    // borderRight: `1px solid ${theme.components.sidebar.border}`,
    '& ul': {
      width: ({ drawerOpen, navWidth }) => (drawerOpen ? navWidth : 72),
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
  },
  secondaryPane: {
    gridArea: 'nav-secondary-pane',
    paddingTop: 16,
    gap: 8,

    '& > h6': {
      marginLeft: 16,
    },
  },
  navText: {
    display: 'grid',
    width: '100%',
    gridAutoFlow: 'column',
    gridTemplateColumns: '72px 1fr',
  },
  textOnlyNavItem: {
    paddingLeft: 32,
  },
  version: {
    margin: theme.spacing(1, 0),
    color: theme.components.sidebar.text,
    textAlign: 'center',
  },
  cloudspaceDropdown: {
    marginLeft: theme.spacing(3),
    width: ({ navWidth }) => `${navWidth - 24 - 24}px`, // sidebar width - left and right padding
    padding: theme.spacing(2, 0, 3, 0),
  },
  allClustersLabel: {
    textTransform: 'uppercase',
    color: theme.palette.grey[300],
    margin: theme.spacing(3, 0, 0.5, 3),
  },
  perClusterLabel: {
    textTransform: 'uppercase',
    color: theme.palette.grey[300],
    padding: theme.spacing(2, 0, 0, 3),
    borderTop: `1px solid ${theme.components.sidebar.border}`,
  },
  label: {
    textTransform: 'uppercase',
    color: theme.palette.grey[300],
    letterSpacing: 2,
  },
}))

export default Sidebar
