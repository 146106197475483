import React from 'react'
import Tooltip from './Tooltip'
import Text from 'pf9-ui-components/built/elements/Text'
import FontAwesomeIcon from 'pf9-ui-components/built/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/styles'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  hint: {
    cursor: 'help',
    transition: 'color .2s ease',
    color: theme.components.input.label.hint,
    '& i': {
      cursor: 'help',
      marginRight: 8,
      transition: 'color .2s ease',
      color: theme.components.input.label.hint,
    },
  },
}))

type HintTooltipProps = {
  message: string
  align: any
  offset: any
  origin?: string
  className?: string
}

const HintTooltip: React.FC<HintTooltipProps> = ({
  message,
  align,
  offset,
  origin = 'right center',
  className,
}) => {
  const classes = useStyles()
  return (
    <Tooltip message={message} align={align} offset={offset} origin={origin} className={className}>
      <Text
        variant="inputLabel"
        className={className || classes.hint}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        {/* <FontAwesomeIcon>question-circle</FontAwesomeIcon>Hint */}
        <FontAwesomeIcon>question-circle</FontAwesomeIcon>
      </Text>
    </Tooltip>
  )
}

export default HintTooltip
