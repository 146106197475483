import React, { useState, useRef, useCallback, useMemo } from 'react'
import { NestedLink } from 'core/plugins/model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import generateTestId from 'utils/test-helpers'
import { bottomRight } from './defaults'
import Menu, { IMenuProps } from './Menu'
import Text from '../Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import clsx from 'clsx'
import Divider from '../Divider'
import NavItem from '../sidebar/NavItem'
import { matchesCurrentPath } from 'core/plugins/route-helpers'
import usePluginRouter from 'core/hooks/usePluginRouter'

interface NavMenuItemProps {
  navItem: NestedLink & { icon?: string }
  index: number
  currentPath: string
  onClose: () => void
  disabled?: boolean
  level?: number
  classes: any
}

const NavMenuItem = React.memo(
  ({ navItem, index, currentPath, onClose, disabled, level = 0, classes }: NavMenuItemProps) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const itemRef = useRef<HTMLDivElement>(null)

    if ((navItem.nestedLinks as NestedLink[])?.length) {
      return (
        <div key={index} className={classes.nestedSection} ref={itemRef}>
          <NavItem
            name={navItem.name}
            link={navItem.link}
            className={clsx(classes.nestedNavItem, classes.parentItem)}
            activeDisplayType="bar"
            isActive={matchesCurrentPath(currentPath, navItem)}
            drawerOpen={true}
            onClick={useCallback((e) => {
              e.preventDefault()
              e.stopPropagation()
              setMenuOpen(true)
            }, [])}
            compact
            variant="sidenav2"
            disabled={disabled}
          />
          <Menu
            className={classes.subMenu}
            open={menuOpen}
            onClose={useCallback(() => setMenuOpen(false), [])}
            anchor={<div ref={itemRef} />}
            origin="left top"
            align={{ horizontal: 'right', vertical: 'top' }}
            offset={{ horizontal: 24, vertical: 0 }}
          >
            <div className={classes.subMenuContent}>
              <div className={classes.titleContainer}>
                <Text
                  className={clsx(classes.title, 'menu-title-text')}
                  component="div"
                  variant="sidenav1"
                >
                  {navItem?.icon && (
                    <FontAwesomeIcon size="lg" className="menu-title-icon" title={navItem.name}>
                      {navItem?.icon}
                    </FontAwesomeIcon>
                  )}
                  {navItem.name}
                </Text>
              </div>
              {(navItem.nestedLinks as NestedLink[]).map((subItem, subIndex) => (
                <NavMenuItem
                  key={`${index}-${subIndex}`}
                  navItem={subItem}
                  index={subIndex}
                  currentPath={currentPath}
                  onClose={onClose}
                  disabled={disabled}
                  level={level + 1}
                  classes={classes}
                />
              ))}
            </div>
          </Menu>
        </div>
      )
    }

    return (
      <NavItem
        key={index}
        name={navItem.name}
        link={navItem.link}
        className={clsx(classes.nestedNavItem, { [classes.subItem]: level > 0 })}
        activeDisplayType="bar"
        isActive={matchesCurrentPath(currentPath, navItem)}
        drawerOpen={true}
        onClick={onClose}
        compact
        variant="sidenav2"
        disabled={disabled}
      />
    )
  },
)

interface Props extends IMenuProps {
  title: string
  icon?: string
  open: boolean
  anchorEl?: JSX.Element
  nestedLinks: NestedLink[]
  onClose: () => void
  disabled?: boolean
  drawerOpen?: boolean
}

export default React.memo(function NavMenu({
  nestedLinks,
  anchorEl = null,
  open,
  onClose,
  icon,
  title,
  className,
  disabled,
  drawerOpen = false,
  ...props
}: Props) {
  const classes = useStyles({ drawerOpen })
  const { currentPath } = usePluginRouter()
  const memoizedNestedLinks = useMemo(
    () =>
      nestedLinks?.map((navItem, index) => (
        <NavMenuItem
          key={index}
          navItem={navItem}
          index={index}
          currentPath={currentPath}
          onClose={onClose}
          disabled={disabled}
          classes={classes}
        />
      )),
    [nestedLinks, currentPath, onClose, disabled, classes],
  )

  return (
    <Menu
      className={clsx(classes.menuContainer, className)}
      data-testid={generateTestId('nav-section', 'menu')}
      origin="left top"
      anchor={anchorEl}
      open={open}
      align={bottomRight.align}
      onClose={onClose}
      {...props}
    >
      <div className={classes.titleContainer}>
        <Text className={clsx(classes.title, 'menu-title-text')} component="div" variant="sidenav1">
          {icon && (
            <FontAwesomeIcon size="lg" className="menu-title-icon" title={title}>
              {icon}
            </FontAwesomeIcon>
          )}
          {title}
        </Text>
        {memoizedNestedLinks}
      </div>
    </Menu>
  )
})

interface StyleProps {
  drawerOpen: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  nestedSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  subMenu: {
    '& .menu-popover': {
      background: theme.components.sidebar.background,
      borderColor: theme.components.sidebar.border,
      borderRadius: theme.spacing(0.5),
      minWidth: ({ drawerOpen }) => (drawerOpen ? 'auto' : 240),
    },
  },
  subMenuContent: {
    padding: theme.spacing(0, 1, 1, 1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  parentItem: {
    fontWeight: 500,
    '&:hover': {
      background: theme.components.sidebar.border,
    },
  },
  subItem: {
    opacity: 0.9,
    fontSize: '0.95em',
  },
  menuContainer: {
    '& .menu-popover': {
      padding: theme.spacing(0, 1, 2, 1),
      background: theme.components.sidebar.background,
      borderColor: theme.components.sidebar.border,
      borderRadius: theme.spacing(0.5),
    },
  },

  titleContainer: {
    padding: theme.spacing(0, 1.5),
  },
  title: {
    display: 'flex',
    padding: theme.spacing(0, 1, 0.5, 1),
    height: 44,
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.components.sidebar?.activeIcon,
    '& i': {
      fontSize: 22,
      width: 24,
      color: theme.components.sidebar.activeText,
    },
    borderBottom: `1px solid ${theme.components.sidebar.border}`,
    marginBottom: theme.spacing(1),
  },
  nestedNavItem: {
    padding: theme.spacing(0, 3),
    '&:hover': {
      borderRadius: theme.spacing(0.5),
      background: theme.components.sidebar.border,
      transition: 'background 0.2s ease',
    },

    '& .tooltip-container': {
      marginLeft: theme.spacing(2),
    },
  },
}))
