import { generateTheme } from '../helpers'
import colors from '../base/colors'
import typography from '../base/typography'
import { components } from './serenityLight'

const serenityCustomTheme = {
  theme: generateTheme({
    palette: { colors, primary: 'blue', secondary: 'pink', themeKey: 'custom', type: 'light' },
    typography: {
      fontFamily: '"Eina04"',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      typography,
    },
  }),
  components,
}

export default serenityCustomTheme
