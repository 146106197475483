import React, { useMemo, useRef, useCallback, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import YamlTemplateParser from 'utils/YamlTemplateParser'
import CodeMirror from 'core/components/codeMirror/CodeMirror'
import { CancellableDebounceFn, debounce } from 'utils/async'
import Text from 'core/elements/Text'
import Divider from 'core/elements/Divider'

export interface YamlTemplate {
  title?: string
  schema: any
  hide?: (values) => boolean
  setValues?: (values) => any
}

interface Props {
  title?: string
  values: any
  yamlTemplates: YamlTemplate[]
  startingNum?: number
  collapseYamls?: boolean
}

export default function YamlTemplates({
  startingNum,
  title = null,
  values,
  yamlTemplates,
  collapseYamls,
}: Props) {
  const classes = useYamlTemplatesStyles()
  return (
    <div className={classes.yamlTemplates}>
      {title ? (
        <Text variant="subtitle2">{title}</Text>
      ) : (
        <Text variant="subtitle2">YAML Scripts</Text>
        /* <Divider className={classes.divider} /> */
      )}
      <div className={classes.yamlContainer}>
        {yamlTemplates.map((yamlTemplate, idx) => {
          if (!yamlTemplate) return null
          const { title, schema, hide, setValues } = yamlTemplate
          values = setValues ? setValues(values) : values
          if (hide && hide(values)) return null
          return (
            <YamlTemplateBlock
              key={idx}
              num={startingNum + idx + 1}
              title={title}
              className={classes.yamlTemplate}
              yamlTemplate={schema}
              values={values}
              showExpandButton
              showCollapseButton
              maxHeight={500}
              collapseYaml={collapseYamls}
            />
          )
        })}
      </div>
    </div>
  )
}

export function YamlTemplateBlock({ title, num, values, yamlTemplate, className = '', ...rest }) {
  const templateParser = useMemo(() => new YamlTemplateParser(yamlTemplate), [yamlTemplate])
  const debounceRef = useRef<CancellableDebounceFn>(undefined)
  const initialParsedYaml = useMemo(() => {
    templateParser.setValues(values)
    return templateParser.toString()
  }, [templateParser, values])
  const [parsedYaml, setParsedYaml] = useState<string>(initialParsedYaml)
  const debouncedYamlParse = useCallback(
    (values) => {
      debounceRef.current?.cancel()
      debounceRef.current = debounce(() => {
        templateParser.setValues(values)
        setParsedYaml(templateParser.toString())
      }, 500)
      debounceRef.current()
    },
    [templateParser],
  )

  useEffect(() => {
    debouncedYamlParse(values)
  }, [yamlTemplate, values])

  useEffect(() => {
    // Cancel debouncing when component gets unmounted
    return () => debounceRef.current?.cancel()
  }, [])

  if (!parsedYaml) {
    return null
  }
  return (
    <CodeMirror
      id={`YamlTemplateBlock-${num}`}
      label={title ? `YAML : ${title}` : `YAML #${num}`}
      value={parsedYaml}
      className={className}
      {...rest}
    ></CodeMirror>
  )
}

const useYamlTemplatesStyles = makeStyles<Theme>((theme) => ({
  divider: {
    margin: theme.spacing(3, 0),
  },
  yamlTemplates: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  yamlContainer: {
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'column nowrap',

    '& .code-mirror-header': {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  yamlTemplate: {
    maxWidth: 470,
    overflowX: 'auto',
  },
  sub2: {
    color: theme.palette.secondary.contrastText,
  },
}))
