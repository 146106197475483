import { makeStyles } from '@material-ui/styles'
import { AppPlugins } from 'app/constants'
import clsx from 'clsx'
import { ClientState, clientStoreKey } from 'core/client/clientReducers'
import Header from 'core/elements/header/Header'
import Sidebar from 'core/elements/sidebar'
import { getSections, renderMainContent } from 'core/plugins/helpers'
import pluginManager from 'core/plugins/pluginManager'
import FrameContext, { IFrameContextRefs, IFullFrameContext } from 'core/providers/frame-provider'
import Theme from 'core/themes/model'
import { createKaapiApplicationCredentials } from 'k8s/components/kaapi/secrets/actions'
import { mergeLeft, prop } from 'ramda'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import { Route } from 'core/plugins/route'
import GlobalClusterDropdown from 'k8s/components/GlobalClusterDropdown'

interface Props {
  role: string
  features: any
  currentPluginId: string
  setPluginId: (id: string) => void
}

const headerActionRef = React.createRef<HTMLDivElement>()
const headerRefreshRef = React.createRef<HTMLDivElement>()

const MainContent = ({ plugins, role, sidebarPane }) => {
  const classes = useStyles({ sidebarPane })
  const { setFrameContainerRef } = React.useContext(FrameContext)
  useEffect(() => {
    setFrameContainerRef({
      headerPrimaryActionContainer: headerActionRef.current,
      headerRefreshContainer: headerRefreshRef.current,
    })
  }, [])

  const hasHeaderContent =
    headerRefreshRef?.current?.childElementCount || headerActionRef?.current?.childElementCount
  const route = Route.getCurrentRoute(location.pathname)
  const showClusterDropdown = route?.defaultParams?.showClusterDropdown === 'true'

  return (
    <section className={clsx('content-main', classes.contentMain)}>
      <div className={clsx(classes.headerContainer, hasHeaderContent && classes.headerSpacing)}>
        <div className={classes.headerActions}>
          {showClusterDropdown && <GlobalClusterDropdown />}
          <div ref={headerRefreshRef} />
        </div>
        <div className={classes.headerActions} ref={headerActionRef} />
      </div>
      {renderMainContent(plugins, role)}
    </section>
  )
}

function DefaultFrame({ currentPluginId, features, role }: Props) {
  const { history, location } = useReactRouter()
  const { frame: { sidebarPane = 'default' } = {} } = useSelector(
    prop<string, ClientState>(clientStoreKey),
  )
  const [frameRefs, setFrameRefs] = useState<IFullFrameContext>({} as any)
  const setDefaultFrameContainerRef = useCallback(
    (payload: Partial<IFrameContextRefs>) => setFrameRefs((frames) => mergeLeft(frames, payload)),
    [frameRefs],
  )

  const plugins = pluginManager.getPlugins()
  const classes = useStyles({ sidebarPane })

  const sections = getSections(plugins, role, features)

  const handlePluginChange = (newPluginId) => {
    const pluginToShow = plugins[newPluginId]
    const defaultRoute = pluginToShow.getDefaultRoute()
    history.push(defaultRoute)
  }
  const frameValue = useMemo(
    () => ({
      ...frameRefs,
      setFrameContainerRef: setDefaultFrameContainerRef,
    }),
    [frameRefs, setDefaultFrameContainerRef],
  )

  return (
    <FrameContext.Provider value={frameValue}>
      <main className={classes.appFrame}>
        <Header />
        <Sidebar setPluginId={handlePluginChange} />
        <MainContent plugins={plugins} role={role} sidebarPane={sidebarPane} />
        {/* <section className={clsx('content-main', classes.contentMain)}>
          {renderMainContent(plugins, role)}
        </section> */}
      </main>
    </FrameContext.Provider>
  )
}

const useStyles = makeStyles<Theme, { sidebarPane: string }>((theme: Theme) => ({
  appFrame: {
    position: 'relative',
    display: 'grid',
    backgroundColor: theme.components.frame.background,
    width: '100vw',
    height: '100vh',
    maxWidth: '100vw',
    maxHeight: '100vh',
    gridTemplateRows: 'minmax(65px, max-content) 1fr',
    gridTemplateColumns: 'max-content 1fr',
    gridTemplateAreas: ({ sidebarPane }) =>
      sidebarPane === 'custom'
        ? '"frame-nav frame-content"'
        : '"frame-nav frame-header" "frame-nav frame-content"',

    '& > header': {
      gridArea: 'frame-header',
      visibility: ({ sidebarPane }) => (sidebarPane === 'custom' ? 'hidden' : 'visible'),
      position: ({ sidebarPane }) => (sidebarPane === 'custom' ? 'absolute' : 'inherit'),
    },
    '& > aside': {
      gridArea: 'frame-nav',
    },
    '& > section': {
      gridArea: 'frame-content',
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '100%',
      height: 1,
      backgroundColor: theme.components.sidebar.border,
    },
  },
  contentMain: {
    display: 'grid',
    padding: '8px 32px 16px',
    overflow: 'auto',
    gridAutoRows: 'max-content',

    // FF supports these fields but not webkit
    scrollbarColor: `${theme?.components?.scrollbar?.thumb} ${theme?.components?.frame?.background}`,
    scrollbarWidth: 'thin',

    '@media screen and (max-width: 768px)': {
      // iOS fix for momentum scrolling
      '-webkit-overflow-scrolling': 'touch',
    },
  },
  secondaryHeader: {
    zIndex: 1100,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerSpacing: {
    marginBottom: 16,
  },
  headerActions: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    gap: 16,
  },
}))

export default DefaultFrame
