import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'
import React from 'react'
import FontAwesomeIcon from './FontAwesomeIcon'
import Tooltip from 'core/elements/tooltip/Tooltip'

interface TitleWithHelpIconProps {
  titleText: string
  tooltipMsg?: string | React.ReactNode
  className?: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  ellipsisText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  hintIcon: {
    marginLeft: theme.spacing(0.5),
  },
}))

const TitleWithInfoIcon = ({ titleText, tooltipMsg, className }: TitleWithHelpIconProps) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.flexBox, className)} title="">
      <p className={clsx(classes.ellipsisText, className)}>{titleText}</p>
      {tooltipMsg && (
        <Tooltip message={tooltipMsg}>
          <>
            <FontAwesomeIcon className={classes.hintIcon}>info-circle</FontAwesomeIcon>
          </>
        </Tooltip>
      )}
    </div>
  )
}

export default TitleWithInfoIcon
