import { IDataKeys } from 'k8s/datakeys.model'
import {
  difference,
  either,
  equals,
  isNil,
  mergeLeft,
  omit,
  pick,
  pickAll,
  pipe,
  reject,
  values,
} from 'ramda'
import Action from 'core/actions/Action'
import store from 'app/store'
import { emptyArr, ensureArray, paramsCartesianProduct } from 'utils/fp'
import { allKey, defaultUniqueIdentifier } from 'app/constants'
import { cacheActions } from 'core/caching/cacheReducers'
import ActionOptions from 'core/actions/ActionOptions'
import { ActionLike } from 'core/actions/ActionLike'
import parseClusterIdsFromParams from '../hooks/parseClusterIdsFromParams'

type ListActionOptions = {
  refetch: boolean
  isDependency?: boolean
  parentActionKey?: string
  ignoreNamespace?: boolean
} & ActionOptions

const { dispatch } = store

export function isListAction<D extends keyof IDataKeys>(
  instance: ActionLike<keyof IDataKeys>,
  cacheKey: D,
): instance is ListAction<D> {
  return instance instanceof ListAction && instance.cacheKey === cacheKey
}

class ListAction<
  D extends keyof IDataKeys,
  P extends Record<string, unknown> = Record<string, unknown>,
  R extends unknown[] = IDataKeys[D],
  O extends ListActionOptions = ListActionOptions
> extends Action<D, P, R, O> {
  private readonly deps = new Set<keyof IDataKeys>()
  private fetchingWithParams = new Set<P>()

  public get name() {
    return 'list'
  }

  public get dependencies(): Array<keyof IDataKeys> {
    return Array.from(this.deps)
  }

  public static initDependencies(
    getListActionsByKey: Record<keyof IDataKeys, ListAction<keyof IDataKeys>>,
  ) {
    ListAction.listActionsByKey = getListActionsByKey
  }

  private static listActionsByKey: Record<keyof IDataKeys, ListAction<keyof IDataKeys>>

  public addDependency = <D extends keyof IDataKeys>(listActionDataKey: D) => {
    if (this.deps.has(listActionDataKey)) {
      throw new Error(
        `"${listActionDataKey}" dependency already exists in ${this.cacheKey} ListAction`,
      )
    }
    this.deps.add(listActionDataKey)
    return this
  }

  protected validate = (params: P, options?: Partial<O>): boolean => {
    const { indexBy = emptyArr, cacheKey } = this.config
    const ignoreNamespace = options?.ignoreNamespace
    const allIndexKeys = indexBy ? ensureArray(indexBy) : emptyArr
    const validIndexKeys = ignoreNamespace
      ? allIndexKeys.filter((k) => k !== 'namespace')
      : allIndexKeys
    // Get the required values from the provided params
    // @ts-ignore
    const providedRequiredParams: P = pipe(pick(validIndexKeys), reject(isNil))(params)
    // Prevent infinite recursion loops when dealing with Action dependencies
    if (this.fetchingWithParams.has(this.memoizedParams(providedRequiredParams))) {
      return false
    }

    // If not all the required params are provided, skip this request and just return an empty array
    if (indexBy && values(providedRequiredParams).length < validIndexKeys.length) {
      const missingDependencyKeys = difference(validIndexKeys, Object.keys(providedRequiredParams))
      if (options?.isDependency) {
        console.error(
          `${
            options?.parentActionKey
          } dependency ${cacheKey} missing parameters ${missingDependencyKeys.join(', ')}`,
        )
      }
      return false
    }
    return true
  }

  private getIndexedParams(params: P): P {
    const { indexBy = emptyArr } = this.config
    const allIndexKeys = indexBy ? ensureArray(indexBy) : emptyArr
    // @ts-ignore
    return this.memoizedParams(pipe(pick(allIndexKeys), reject(isNil))(params))
  }

  protected async preProcess(params: P, options: Partial<O>): Promise<void> {
    const { cacheKey } = this.config
    const { updateLoadingState = true, refetch, ignoreNamespace = false } = options
    if (updateLoadingState) {
      dispatch(cacheActions.setLoading({ cacheKey, loading: true }))
    }
    const indexedParams = this.getIndexedParams(params)
    const validIndexedParams = ignoreNamespace ? omit(['namespace'], indexedParams) : indexedParams
    // @ts-ignore
    this.fetchingWithParams.add(validIndexedParams)

    // Ignoring the dependencies, as for few actions we might not need to add dependencies
    // While the same actions might be used on different places where adding dependency is required
    if (options?.ignoreDependency) return

    // Call all the dependencies recursively
    this.deps.forEach((listActionDataKey) => {
      const dependantListAction = ListAction.listActionsByKey[listActionDataKey]
      if (!dependantListAction) {
        throw new Error(`${listActionDataKey} not found in listActionsByKey map, please add it`)
      }
      // const dependantListAction = this.getInstance(listActionDataKey)
      if (isListAction(dependantListAction, listActionDataKey)) {
        // Resolve allKey when using clusterId
        if (dependantListAction?.config?.indexBy?.includes('clusterId')) {
          paramsCartesianProduct({
            ...validIndexedParams,
            clusterId: parseClusterIdsFromParams(validIndexedParams),
          }).forEach((clusterParams) =>
            dependantListAction.call(clusterParams, {
              updateLoadingState,
              refetch,
              isDependency: true,
              parentActionKey: cacheKey,
            }),
          )
          return
        }
        // @fixme find a better way of doing this
        dependantListAction.call(validIndexedParams as any, {
          updateLoadingState,
          refetch,
          isDependency: true,
          parentActionKey: cacheKey,
        })
      }
    })
  }

  protected postProcess = (result: R, params: P, options: O): Promise<void> | void => {
    const {
      cacheKey,
      uniqueIdentifier = defaultUniqueIdentifier,
      indexBy = emptyArr,
      cache = true,
    } = this.config
    const { refetch = false, updateLoadingState = true, ignoreNamespace = false } = options
    const allIndexKeys = indexBy ? ensureArray(indexBy) : emptyArr
    const validIndexKeys = ignoreNamespace
      ? allIndexKeys.filter((k) => k !== 'namespace')
      : allIndexKeys

    // Get the required values from the provided params
    const indexedParams = this.getIndexedParams(params)
    const validIndexedParams = ignoreNamespace ? omit(['namespace'], indexedParams) : indexedParams
    // @ts-ignore
    this.fetchingWithParams.delete(validIndexedParams)

    // If not all the required params are provided, skip this request and just return an empty array
    if (indexBy && values(validIndexedParams).length < validIndexKeys.length) {
      return
    }

    const providedIndexedParams = pipe(
      pickAll(validIndexKeys),
      reject(either(isNil, equals(allKey))),
    )(params)

    // We can't rely on the server to index the data, as sometimes it simply doesn't return the
    // params used for the query, so we will add them to the items in order to be able to find
    // them afterwards
    const itemsWithParams =
      result instanceof Array ? result.map(mergeLeft(providedIndexedParams)) : result

    // Perfom the cache update operations
    if (!cache || refetch) {
      dispatch(
        cacheActions.replaceAll({
          cacheKey,
          items: itemsWithParams,
          params: cache ? providedIndexedParams : null,
        }),
      )
    } else {
      dispatch(
        cacheActions.upsertAll({
          uniqueIdentifier,
          cacheKey,
          items: itemsWithParams,
          params: providedIndexedParams,
        }),
      )
    }
    if (updateLoadingState) {
      dispatch(cacheActions.setLoading({ cacheKey, loading: false }))
    }
  }

  protected handleError(err, params: P, options) {
    const { cacheKey } = this.config
    const { updateLoadingState = true } = options
    // Without this, if an error occurs loading can be stuck as true
    if (updateLoadingState) {
      dispatch(cacheActions.setLoading({ cacheKey, loading: false }))
    }
    this.fetchingWithParams.delete(this.getIndexedParams(params))

    return super.handleError(err, params, options)
  }
}

export default ListAction
