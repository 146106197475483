import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { RenderLabels } from './renderLabels'
import { path, applySpec, complement, isNil } from 'ramda'
import clsx from 'clsx'
import { WizardSummaryProps } from './model'
import CloudProviderCard from 'k8s/components/common/CloudProviderCard'
import generateTestId from 'utils/test-helpers'
import Alert from '../Alert'
import { emptyArr, isNilOrEmpty } from 'utils/fp'
import YamlTemplates from './YamlTemplates'
import Button from 'core/elements/button'
import YamlTemplateParser from 'utils/YamlTemplateParser'
import JSZip from 'jszip'
import FontAwesomeIcon from '../FontAwesomeIcon'

export enum CapiResourceKind {
  Cluster = 'Cluster',
  AWSCluster = 'AWSCluster',
  NodeletControlPlane = 'NodeletControlPlane',
  AWSManagedControlPlane = 'AWSManagedControlPlane',
  AWSMachineTemplate = 'AWSMachineTemplate',
  AWSClusterRoleIdentity = 'AWSClusterRoleIdentity',
  MachineDeployment = 'MachineDeployment',
  MachinePool = 'MachinePool',
  AWSMachinePool = 'AWSMachinePool',
  AWSManagedMachinePool = 'AWSManagedMachinePool',
  NodeletConfig = 'NodeletConfig',
  NodeletConfigTemplate = 'NodeletConfigTemplate',
  EksConfig = 'EKSConfig',
  EksConfigTemplate = 'EKSConfigTemplate',
  ClusterAddon = 'ClusterAddon',
}

/*
  An opinionated meta component that will call out specific important fields as they are filled
*/

const parseYaml = (schema, values) => {
  const templateParser = new YamlTemplateParser(schema)
  templateParser.setValues(values)
  return templateParser.toString()
}

// Zip all YAML files. The YAMLS are organized into subdirectories that correspond to the
// wizard step ID. For example, all YAMLS in the addons step are under the
// 'addons' folder
const handleDownloadAllYamls = (steps, allValues) => {
  try {
    const zip = new JSZip()

    steps.forEach(({ stepId, yamlTemplates }) => {
      if (isNilOrEmpty(yamlTemplates)) return
      zip.folder(stepId)
      yamlTemplates.forEach((template) => {
        const { schema, hide, setValues } = template
        const yamlValues = setValues ? setValues(allValues) : allValues
        if (hide && hide(yamlValues)) return
        const yaml = parseYaml(schema, yamlValues)
        const { kind } = schema
        const fileName = kind === CapiResourceKind.ClusterAddon ? schema.spec?.type : kind

        if (stepId === 'workerNodeGroups') {
          // Since a cluster can have multiple node groups, they will be organized into additional
          // subdirectories by name
          zip
            .folder(stepId)
            .folder(yamlValues.name)
            .file(`${kind}.yaml`, yaml as string)
        } else {
          zip.folder(stepId).file(`${fileName}.yaml`, yaml as string)
        }
      })
    })

    zip.generateAsync({ type: 'blob' }).then(function(content) {
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(content)
      elem.download = allValues.name
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    })
  } catch (error) {
    console.error('Error downloading YAML files', error)
  }
}

const asideOptionIcons = {
  yaml: 'code',
  help: 'circle-question',
}

export default function WizardSummary<T>({
  children,
  keyOverrides = undefined,
  calloutFields = [],
  currentStep = {},
  icon,
  error,
  errors,
  fields = {} as any,
  wizardContext,
  sidebarActions,
  title,
  footer,
  className,
  steps,
  showSummaryLabels = true,
  showSummaryYamls = false,
  renderLabels = (labels) => (
    <RenderLabels keyOverrides={keyOverrides} labels={labels} inverse split />
  ),
}: PropsWithChildren<WizardSummaryProps<T>>) {
  const classes = useStyles()
  const [activeAsideOption, setAsideOption] = useState(null)
  const allFields = { ...wizardContext, ...fields }
  const formatLabels = useMemo(() => {
    const labelSpec = {}
    Object.keys(keyOverrides || []).forEach((override) => {
      const foundField: any = calloutFields.find((field: any) => field.indexOf(override) > -1) || ''

      labelSpec[override] = path(foundField.split('.'))
    })
    return applySpec(labelSpec) as any
  }, [keyOverrides, calloutFields])

  const labels = formatLabels(allFields)
  const hasLabels = Object.entries(labels).length > 0

  const {
    label,
    yamlTemplates = emptyArr,
    showAllYamls,
    collapseYamls,
    asideOptions,
    HelpComponent,
  } = currentStep
  useEffect(() => {
    setAsideOption(currentStep?.asideOptions?.[0] || null)
  }, [currentStep])
  const showTitleStep = !!label
  const titleStepLabel = showTitleStep ? (
    <span className={classes.wizardHeaderLight}>{`${title ? '/ ' : ''}${label}`}</span>
  ) : null
  const renderedYamls = useMemo(() => {
    let startingNumAcc = 0
    if (showAllYamls) {
      const renderedYamls = steps
        .map(({ yamlTemplates, label, stepId }) => {
          const startingNum = startingNumAcc
          startingNumAcc += yamlTemplates?.length || 0
          return yamlTemplates?.length ? (
            <YamlTemplates
              title={label}
              startingNum={startingNum}
              key={stepId}
              values={allFields}
              collapseYamls={collapseYamls}
              yamlTemplates={yamlTemplates}
            />
          ) : null
        })
        .filter(complement(isNil))
      return renderedYamls.length ? (
        <>
          <Button
            variant="secondary"
            rightIcon="download"
            classsName={classes.downloadYamlsButton}
            onClick={() => handleDownloadAllYamls(steps, allFields)}
          >
            Download all YAML's
          </Button>
          {renderedYamls}
        </>
      ) : null
    }
    return yamlTemplates?.length ? (
      <>
        <YamlTemplates
          values={allFields}
          yamlTemplates={yamlTemplates}
          collapseYamls={collapseYamls}
        />
      </>
    ) : null
  }, [steps, allFields, yamlTemplates, showAllYamls, collapseYamls])
  return (
    <article className={clsx(classes.wizardSummary, className)}>
      <header className={classes.wizardHeader}>
        {icon ? (
          <figure className={classes.wizardFigure}>
            <CloudProviderCard active type={icon} asCard={false} />
          </figure>
        ) : null}
        <Text data-testid={generateTestId(title)} variant="subtitle1">
          {title} {titleStepLabel}
        </Text>
      </header>
      <section className={classes.wizardBody}>{children}</section>
      <aside className={classes.wizardAside}>
        <div className={classes.wizardAsideContent}>
          {(!asideOptions || activeAsideOption === 'yaml') && (
            <>
              {showSummaryLabels && hasLabels && <Text variant="subtitle2">Summary</Text>}
              {!!error && <Alert variant="error" title={error?.title} message={error?.message} />}
              {!!errors?.length &&
                errors.map((error, idx) => (
                  <Alert key={idx} variant="error" title={error?.title} message={error?.message} />
                ))}
              {showSummaryLabels && hasLabels && renderLabels(labels)}
              {showSummaryYamls && renderedYamls}
              {sidebarActions && <div className={classes.wizardAsideActions}>{sidebarActions}</div>}
            </>
          )}
          {activeAsideOption === 'help' && HelpComponent && (
            <>
              <HelpComponent />
            </>
          )}
        </div>
        {asideOptions && (
          <div className={classes.wizardAsideOptions}>
            {asideOptions?.map((option) => (
              <div
                onClick={() => setAsideOption(option)}
                className={clsx(classes.asideOption, {
                  [classes.activeAsideOption]: activeAsideOption === option,
                })}
              >
                <FontAwesomeIcon>{asideOptionIcons[option]}</FontAwesomeIcon>
              </div>
            ))}
          </div>
        )}
      </aside>
      <footer className={classes.wizardFooter}>{footer}</footer>
    </article>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  wizardSummary: {
    backgroundColor: theme.components.card.background,
    borderRadius: '4px',
    border: `1px solid ${theme.components.card.border}`,
    display: 'grid',
    minHeight: 500,
    minWidth: 'min-content',
    gridTemplateColumns: 'minmax(min-content, 1fr) minmax(min-content, 500px)',
    gridTemplateRows: 'max-content 1fr max-content',
    gridTemplateAreas: `
      "wm-form-header wm-form-header"
      "wm-form-fields wm-form-summary"
      "wm-form-footer wm-form-summary"
      `,
  },
  wizardFigure: {
    margin: 0,
    padding: 0,
    '& img': {
      maxHeight: 74,
    },
  },
  wizardHeader: {
    gridArea: 'wm-form-header',
    borderBottom: `1px solid ${theme.components.card.border}`,
    padding: '0 24px',
    height: 112,
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gridAutoColumns: 'max-content',
    gap: 16,
  },
  wizardBody: {
    display: 'grid',
    gap: '16px',
    padding: '32px 64px',
  },
  wizardAside: {
    display: 'grid',
    gridArea: 'wm-form-summary',
    borderLeft: `1px solid ${theme.components.card.border}`,
    // alignItems: 'start',
    justifyContent: 'center',
    // gridAutoColumns: 'minmax(min-content, 1fr)',
    gridTemplateColumns: '1fr max-content',
    alignItems: 'stretch',
  },
  wizardAsideOptions: {
    borderLeft: `1px solid ${theme.components.card.border}`,
    width: 64,
    display: 'flex',
    gap: 10,
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0px',
  },
  asideOption: {
    cursor: 'pointer',
    height: 44,
    width: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.components.button.secondary.background,
    '&:hover': {
      background: theme.components.button.secondary.activeBackground,
    },
  },
  activeAsideOption: {
    background: theme.components.button.secondary.activeBackground,
  },
  wizardAsideContent: {
    // minWidth: 250,
    // maxWidth: 350,
    // width: '100%',
    padding: theme.spacing(4, 2),
    display: 'grid',
    gap: 24,
    gridAutoRows: 'max-content',
  },
  wizardAsideActions: {
    marginTop: 24,
    display: 'grid',
  },
  wizardHeaderLight: {
    fontWeight: 300,
  },
  wizardFooter: {
    gridArea: 'wm-form-footer',
    borderTop: `1px solid ${theme.components.card.border}`,
    padding: '16px 32px',
    display: 'grid',
    gridAutoFlow: 'column',
    justifyItems: 'end',
  },
}))
