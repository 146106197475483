import { matchPath } from 'react-router'
import { memoize } from 'utils/misc'
import { IRouterLink } from 'core/plugins/model'

export const matchLinkToPath = (url) =>
  memoize((link: IRouterLink) => {
    if (matchesCurrentPath(url, link.link)) {
      return true
    } else if (link?.nestedLinks?.some(({ link }) => matchesCurrentPath(url, link))) {
      return true
    }
    return false
  })

export const matchesCurrentPath = memoize(
  (url, navItem) =>
    navItem?.link &&
    (matchPath(url, {
      path: navItem?.link?.definition || navItem?.link?.path,
      exact: navItem?.link?.exact || false,
      strict: false,
    }) ||
      navItem?.nestedLinks?.find((nestedLink) => matchesCurrentPath(url, nestedLink))),
)
