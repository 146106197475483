import { AppPlugins, helpUrlDefinition, pluginRoutePrefix } from 'app/constants'
import { Route } from 'core/plugins/route'
import { IVmDetailsPageTabs } from 'openstack/components/vms/vm-details/model'
import { INetworkDetailsPageTabs } from 'openstack/components/networks/network-details/model'
import { IRouterDetailsPageTabs } from 'openstack/components/networks/routers/model'
import { IStackDetailsPageTabs } from 'openstack/components/orchestration/model'
import { IHostDetailsPageTabs } from 'openstack/components/infrastructure/hosts/host-details/model'
import { IVolumeDetailsPageTabs } from 'openstack/components/storage/volumes/volume-details/model'
import { ILoadBalancerDetailsPageTabs } from 'app/plugins/openstack/components/networks/model'

const defaultParams = {
  plugin: AppPlugins.OpenStack,
}

const metadata = {
  showGlobalFilters: true,
}

export const openstackRoutes = {
  openstack: {
    dashboard: Route.register({
      url: `${pluginRoutePrefix}/dashboard`,
      name: 'Dashboard',
      id: 'openstackDashboard',
      breadcrumbs: new Map([['dashboard', 'openstackDashboard']]),
      metadata,
      defaultParams,
    }),
    images: Route.register({
      url: `${pluginRoutePrefix}/images`,
      name: 'Images',
      id: 'openstackImages',
      breadcrumbs: new Map([['images', 'openstackImages']]),
      metadata,
      defaultParams,
    }),
    addImage: Route.register({
      url: `${pluginRoutePrefix}/images/add`,
      name: 'Images:Add',
      breadcrumbs: new Map([
        ['images', 'openstackImages'],
        ['add', 'openstackImages'],
      ]),
      metadata,
      defaultParams,
    }),
    importImage: Route.register({
      url: `${pluginRoutePrefix}/images/import`,
      name: 'Images:Import',
      breadcrumbs: new Map([
        ['images', 'openstackImages'],
        ['import', 'openstackImages'],
      ]),
      metadata,
      defaultParams,
    }),
    vms: Route.register({
      url: `${pluginRoutePrefix}/compute/vms`,
      name: 'Virtual Machines',
      id: 'openstackVms',
      breadcrumbs: new Map([['VM Instances', 'openstackVms']]),
      metadata,
      defaultParams,
    }),
    vmDetails: Route.register<{
      id: string
      tab?: IVmDetailsPageTabs
    }>({
      url: `${pluginRoutePrefix}/compute/vms/:id/:tab`,
      name: 'VirtualMachines:Details',
      id: 'openstackVmDetails',
      breadcrumbs: new Map([
        ['VM Instances', 'openstackVms'],
        [':id', 'openstackVmDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      tab: 'tab',
    }),
    deployVm: Route.register({
      url: `${pluginRoutePrefix}/compute/vms/deploy`,
      name: 'OpenstackVms:Deploy',
      breadcrumbs: new Map([
        ['VM Instances', 'openstackVms'],
        ['deploy', 'openstackVms'],
      ]),
      metadata,
      defaultParams,
    }),
    serverGroups: Route.register({
      url: `${pluginRoutePrefix}/compute/server-groups`,
      name: 'Server Groups',
      id: 'serverGroups',
      breadcrumbs: new Map([['Server Groups', 'serverGroups']]),
      metadata,
      defaultParams,
    }),
    createServerGroup: Route.register({
      url: `${pluginRoutePrefix}/compute/server-groups/create`,
      name: 'Server Groups:Create',
      breadcrumbs: new Map([
        ['Server Groups', 'serverGroups'],
        ['create', 'serverGroups'],
      ]),
      metadata,
      defaultParams,
    }),
    flavors: Route.register({
      url: `${pluginRoutePrefix}/flavors`,
      name: 'Flavors',
      id: 'openstackFlavors',
      breadcrumbs: new Map([['flavors', 'openstackFlavors']]),
      metadata,
      defaultParams,
    }),
    createFlavor: Route.register({
      url: `${pluginRoutePrefix}/flavors/create`,
      name: 'OpenstackFlavors:Create',
      breadcrumbs: new Map([
        ['Flavors', 'openstackFlavors'],
        ['create', 'openstackFlavors'],
      ]),
      metadata,
      defaultParams,
    }),
    volumes: Route.register({
      url: `${pluginRoutePrefix}/storage/volumes`,
      name: 'Volumes',
      id: 'openstackVolumes',
      breadcrumbs: new Map([['volumes', 'openstackVolumes']]),
      metadata,
      defaultParams,
    }),
    volumeDetails: Route.register<{
      id: string
      tab?: IVolumeDetailsPageTabs
    }>({
      url: `${pluginRoutePrefix}/storage/volumes/:id/:tab`,
      name: 'Volumes:Details',
      id: 'openstackVolumeDetails',
      breadcrumbs: new Map([
        ['volumes', 'openstackVolumes'],
        [':id', 'openstackVolumeDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      tab: 'tab',
    }),
    createVolume: Route.register({
      url: `${pluginRoutePrefix}/storage/volumes/create`,
      name: 'OpenstackVolumes:Create',
      breadcrumbs: new Map([
        ['volumes', 'openstackVolumes'],
        ['create', 'openstackVolumes'],
      ]),
      metadata,
      defaultParams,
    }),
    volumeTypes: Route.register({
      url: `${pluginRoutePrefix}/storage/volume-types`,
      name: 'VolumeTypes',
      id: 'volumeTypes',
      breadcrumbs: new Map([['volume-types', 'volumeTypes']]),
      metadata,
      defaultParams,
    }),
    createVolumeType: Route.register({
      url: `${pluginRoutePrefix}/storage/volume-types/create`,
      name: 'VolumeTypes:Create',
      breadcrumbs: new Map([
        ['volume-types', 'volumeTypes'],
        ['create', 'volumeTypes'],
      ]),
      metadata,
      defaultParams,
    }),
    volumeSnapshots: Route.register({
      url: `${pluginRoutePrefix}/storage/snapshots`,
      name: 'Volume Snapshots',
      id: 'openstackVolumeSnapshots',
      breadcrumbs: new Map([['volume-snapshots', 'openstackVolumeSnapshots']]),
      metadata,
      defaultParams,
    }),
    networks: Route.register({
      url: `${pluginRoutePrefix}/networking/networks`,
      name: 'Networks',
      id: 'openstackNetworks',
      breadcrumbs: new Map([['physical-networks', 'openstackNetworks']]),
      metadata,
      defaultParams,
    }),
    networkDetails: Route.register<{
      id: string
      tab?: INetworkDetailsPageTabs
    }>({
      url: `${pluginRoutePrefix}/networking/networks/:id/:tab`,
      name: 'Networks:Details',
      id: 'openstackNetworkDetails',
      breadcrumbs: new Map([
        ['physical-networks', 'openstackNetworks'],
        [':id', 'openstackNetworkDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      tab: 'tab',
    }),
    createNetwork: Route.register({
      url: `${pluginRoutePrefix}/networking/networks/create`,
      name: 'OpenstackNetworks:Create',
      breadcrumbs: new Map([
        ['physical-networks', 'openstackNetworks'],
        ['create', 'openstackNetworks'],
      ]),
      metadata,
      defaultParams,
    }),
    createPhysicalNetworkSubnet: Route.register({
      url: `${pluginRoutePrefix}/networking/networks/:id/subnets/create`,
      name: 'OpenstackSubnets:CreatePhysicalNetworkSubnet',
      breadcrumbs: new Map([
        ['physical-networks', 'openstackNetworks'],
        [':id', 'openstackNetworkDetails'],
      ]),
      metadata,
      defaultParams,
    }),
    createPhysicalNetworkPort: Route.register({
      url: `${pluginRoutePrefix}/networking/networks/:id/ports/create`,
      name: 'OpenstackSubnets:CreatePhysicalNetworkPort',
      breadcrumbs: new Map([
        ['physical-networks', 'openstackNetworks'],
        [':id', 'openstackNetworkDetails'],
      ]),
      metadata,
      defaultParams,
    }),
    virtualNetworks: Route.register({
      url: `${pluginRoutePrefix}/networking/virtual-networks`,
      name: 'VirtualNetworks',
      id: 'virtualNetworks',
      breadcrumbs: new Map([['virtual-networks', 'virtualNetworks']]),
      metadata,
      defaultParams,
    }),
    virtualNetworkDetails: Route.register<{
      id: string
      tab?: INetworkDetailsPageTabs
    }>({
      url: `${pluginRoutePrefix}/networking/virtual-networks/:id/:tab`,
      name: 'VirtualNetworks:Details',
      id: 'virtualNetworkDetails',
      breadcrumbs: new Map([
        ['virtual-networks', 'virtualNetworks'],
        [':id', 'virtualNetworkDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      tab: 'tab',
    }),
    createVirtualNetwork: Route.register({
      url: `${pluginRoutePrefix}/networking/virtual-networks/create`,
      name: 'VirtualNetworks:Create',
      breadcrumbs: new Map([
        ['virtual-networks', 'virtualNetworks'],
        ['create', 'virtualNetworks'],
      ]),
      metadata,
      defaultParams,
    }),
    vmwareNetworks: Route.register({
      url: `${pluginRoutePrefix}/networking/vmw-networks`,
      name: 'VmwNetworks',
      id: 'openstackVmwNetworks',
      breadcrumbs: new Map([['networks', 'openstackVmwNetworks']]),
      metadata,
      defaultParams,
    }),
    vmwareNetworkDetails: Route.register<{
      id: string
      tab?: INetworkDetailsPageTabs
    }>({
      url: `${pluginRoutePrefix}/networking/vmw-networks/:id/:tab`,
      name: 'VmwNetworks:Details',
      id: 'openstackVmwNetworkDetails',
      breadcrumbs: new Map([
        ['networks', 'openstackVmwNetworks'],
        [':id', 'openstackVmwNetworkDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      tab: 'tab',
    }),
    createVmwareNetwork: Route.register({
      url: `${pluginRoutePrefix}/networking/vmw-networks/create`,
      name: 'OpenstackVmwNetworks:Create',
      breadcrumbs: new Map([
        ['networks', 'openstackVmwNetworks'],
        ['create', 'openstackVmwNetworks'],
      ]),
      metadata,
      defaultParams,
    }),
    createVirtualNetworkSubnet: Route.register({
      url: `${pluginRoutePrefix}/networking/virtual-networks/:id/subnets/create`,
      name: 'OpenstackSubnets:CreateVirtualNetworkSubnet',
      breadcrumbs: new Map([
        ['virtual-networks', 'virtualNetworks'],
        [':id', 'virtualNetworkDetails'],
      ]),
      metadata,
      defaultParams,
    }),
    createVmwSubnet: Route.register({
      url: `${pluginRoutePrefix}/networking/vmw-networks/:id/subnets/create`,
      name: 'OpenstackVmwSubnets:CreateVmwSubnet',
      breadcrumbs: new Map([
        ['networks', 'openstackVmwNetworks'],
        [':id', 'openstackVmwNetworkDetails'],
      ]),
      metadata,
      defaultParams,
    }),
    createVirtualNetworkPort: Route.register({
      url: `${pluginRoutePrefix}/networking/virtual-networks/:id/ports/create`,
      name: 'OpenstackPorts:CreateVirtualNetworkPort',
      breadcrumbs: new Map([
        ['virtual-networks', 'virtualNetworks'],
        [':id', 'virtualNetworkDetails'],
      ]),
      metadata,
      defaultParams,
    }),
    createVmwPort: Route.register({
      url: `${pluginRoutePrefix}/networking/vmw-networks/:id/ports/create`,
      name: 'OpenstackVmwPorts:CreateVmwPort',
      breadcrumbs: new Map([
        ['networks', 'openstackVmwNetworks'],
        [':id', 'openstackVmwNetworkDetails'],
      ]),
      metadata,
      defaultParams,
    }),
    routers: Route.register({
      url: `${pluginRoutePrefix}/networking/routers`,
      name: 'Routers',
      id: 'openstackRouters',
      breadcrumbs: new Map([['routers', 'openstackRouters']]),
      metadata,
      defaultParams,
    }),
    routerDetails: Route.register<{
      id: string
      tab?: IRouterDetailsPageTabs
    }>({
      url: `${pluginRoutePrefix}/networking/routers/:id/:tab`,
      name: 'Routers:Details',
      id: 'routerDetails',
      breadcrumbs: new Map([
        ['routers', 'openstackRouters'],
        [':id', 'routerDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      tab: 'tab',
    }),
    createRouter: Route.register({
      url: `${pluginRoutePrefix}/networking/routers/create`,
      name: 'OpenstackRouters:Create',
      breadcrumbs: new Map([
        ['routers', 'openstackRouters'],
        ['create', 'openstackRouters'],
      ]),
      metadata,
      defaultParams,
    }),
    createRouterInterface: Route.register({
      url: `${pluginRoutePrefix}/networking/routers/:id/interfaces/create`,
      name: 'OpenstackRouterInterface:Create',
      breadcrumbs: new Map([
        ['routers', 'openstackRouters'],
        [':id', 'routerDetails'],
      ]),
      metadata,
      defaultParams,
    }),
    clusterBlueprint: Route.register({
      url: `${pluginRoutePrefix}/infrastructure/blueprint`,
      name: 'ClusterBlueprint',
      id: 'clusterBlueprint',
      breadcrumbs: new Map([['blueprint', 'clusterBlueprint']]),
      metadata,
      defaultParams,
    }),
    clusterServerRoles: Route.register({
      url: `${pluginRoutePrefix}/infrastructure/host-roles`,
      name: 'ClusterHostRoles',
      id: 'clusterHostRoles',
      breadcrumbs: new Map([['host-roles', 'clusterHostRoles']]),
      metadata,
      defaultParams,
    }),
    hosts: Route.register({
      url: `${pluginRoutePrefix}/infrastructure/hosts`,
      name: 'Cluster Hosts',
      id: 'openstackHosts',
      breadcrumbs: new Map([['cluster-hosts', 'openstackHosts']]),
      metadata,
      defaultParams,
    }),
    hostDetails: Route.register<{
      id: string
      tab?: IHostDetailsPageTabs
    }>({
      url: `${pluginRoutePrefix}/infrastructure/hosts/:id/:tab`,
      name: 'ClusterHosts:Details',
      id: 'hostDetails',
      breadcrumbs: new Map([
        ['cluster-hosts', 'openstackHosts'],
        [':id', 'hostDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      tab: 'tab',
    }),
    addHosts: Route.register({
      url: `${pluginRoutePrefix}/infrastructure/hosts/add`,
      name: 'Add Cluster Hosts',
      id: 'addOpenstackHosts',
      breadcrumbs: new Map([['cluster-hosts', 'openstackHosts']]),
      metadata,
      defaultParams,
    }),
    addVmwareGateway: Route.register({
      url: `${pluginRoutePrefix}/infrastructure/hosts/add-gateway`,
      name: 'Add VMware Gateway',
      id: 'addVmwareGateway',
      breadcrumbs: new Map([['cluster-hosts', 'vmwareGateway']]),
      metadata,
      defaultParams,
    }),
    hostAggregates: Route.register({
      url: `${pluginRoutePrefix}/infrastructure/host-aggregates`,
      name: 'Host Aggregates',
      id: 'openstackHostAggregates',
      breadcrumbs: new Map([['host-aggregates', 'openstackHostAggregates']]),
      metadata,
      defaultParams,
    }),
    createHostAggregate: Route.register({
      url: `${pluginRoutePrefix}/infrastructure/host-aggregates/create`,
      name: 'Add Host Aggregate',
      id: 'createHostAggregate',
      breadcrumbs: new Map([['host-aggregates', 'openstackHostAggregates']]),
      metadata,
      defaultParams,
    }),
    sshKeys: Route.register({
      url: `${pluginRoutePrefix}/networking/ssh-key`,
      name: 'ssh-key',
      id: 'ssh-key',
      breadcrumbs: new Map([['SSH key', 'ssh-key']]),
      metadata,
      defaultParams,
    }),
    importSshKey: Route.register({
      url: `${pluginRoutePrefix}/networking/ssh-key/import`,
      name: 'ssh-key:Import',
      breadcrumbs: new Map([
        ['SSH Key', 'ssh-key'],
        ['Import SSH Key', 'ssh-key'],
      ]),
      metadata,
      defaultParams,
    }),
    floatingIps: Route.register({
      url: `${pluginRoutePrefix}/networking/public-ips`,
      name: 'Public IPs',
      id: 'publicIps',
      breadcrumbs: new Map([['public-IPs', 'publicIps']]),
      metadata,
      defaultParams,
    }),
    createFloatingIp: Route.register({
      url: `${pluginRoutePrefix}/networking/public-ips/create`,
      name: 'Public IPs:Create',
      breadcrumbs: new Map([
        ['public-IPs', 'publicIps'],
        ['create', 'publicIps'],
      ]),
      metadata,
      defaultParams,
    }),
    securityGroups: Route.register({
      url: `${pluginRoutePrefix}/networking/security-groups`,
      name: 'Security Groups',
      id: 'securityGroups',
      breadcrumbs: new Map([['security-groups', 'securityGroups']]),
      metadata,
      defaultParams,
    }),
    createSecurityGroup: Route.register({
      url: `${pluginRoutePrefix}/networking/security-groups/create`,
      name: 'Security Groups:Create',
      breadcrumbs: new Map([
        ['security-groups', 'securityGroups'],
        ['create', 'securityGroups'],
      ]),
      metadata,
      defaultParams,
    }),
    dns: Route.register({
      url: `${pluginRoutePrefix}/networking/dns/:tab`,
      name: 'DNS',
      id: 'dns',
      breadcrumbs: new Map([['DNS', 'dns']]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'pools',
        view: 'list',
      },
    }),
    createDnsResource: Route.register({
      url: `${pluginRoutePrefix}/networking/dns/:tab/create`,
      name: 'DNS',
      id: 'dns',
      breadcrumbs: new Map([['DNS', 'dns']]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'pools',
      },
    }),
    editDnsResource: Route.register({
      url: `${pluginRoutePrefix}/networking/dns/:tab/edit/:id`,
      name: 'DNS',
      id: 'dns',
      breadcrumbs: new Map([['DNS', 'dns']]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'pools',
      },
    }),
    loadBalancers: {
      details: Route.register<{
        tab?: ILoadBalancerDetailsPageTabs
      }>({
        id: 'loadBalancersDetails',
        url: `${pluginRoutePrefix}/networking/load-balancers/:tab`,
        breadcrumbs: new Map([['load-balancer', 'loadBalancersDetails']]),
        metadata,
        defaultParams: {
          ...defaultParams,
          tab: 'loadBalancers',
        },
        name: 'Load Balancer',
        tab: 'tab',
      }),
    },
    createLoadBalancers: Route.register<{
      tab?: ILoadBalancerDetailsPageTabs
    }>({
      url: `${pluginRoutePrefix}/networking/load-balancers/:tab/create`,
      name: 'Load Balancer:Create',
      breadcrumbs: new Map([['load-balancer', 'loadBalancersDetails']]),
      metadata,
      defaultParams,
    }),

    stacks: Route.register({
      url: `${pluginRoutePrefix}/orchestration/stacks`,
      name: 'Stacks',
      id: 'stacks',
      breadcrumbs: new Map([['stacks', 'stacks']]),
      metadata,
      defaultParams,
    }),
    createStack: Route.register({
      url: `${pluginRoutePrefix}/orchestration/stacks/create`,
      name: 'Stacks:Create',
      breadcrumbs: new Map([
        ['stacks', 'stacks'],
        ['create', 'stacks'],
      ]),
      metadata,
      defaultParams,
    }),
    stackDetails: Route.register<{
      id: string
      name: string
      tab?: IStackDetailsPageTabs
    }>({
      url: `${pluginRoutePrefix}/orchestration/stacks/:name/:id/:tab`,
      name: 'Stacks:Details',
      id: 'stackDetails',
      breadcrumbs: new Map([
        ['stacks', 'stacks'],
        [':name', 'stackDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      tab: 'tab',
    }),
    notifications: {
      list: Route.register<{ notificationType: string }>({
        url: `${pluginRoutePrefix}/notifications/:notificationType`,
        name: 'Notifications:List',
        metadata,
        defaultParams,
      }),
      detail: Route.register<{ notificationType: string; id: string }>({
        url: `${pluginRoutePrefix}/notifications/:notificationType/:id`,
        name: 'Notifications:Details',
        metadata,
        defaultParams,
      }),
    },
    help: Route.register({
      url: helpUrlDefinition,
      name: 'Help',
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'support',
      },
    }),
  },
}
