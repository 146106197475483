import { makeStyles } from '@material-ui/styles'
import colors from 'core/themes/base/colors'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React from 'react'
import { useMemo } from 'react'
export enum IconStatus {
  InProgress = 'inProgress',
  Success = 'success',
  Failed = 'failed',
  NotStarted = 'notStarted',
  Warning = 'warning',
}
export interface SubStep {
  stepId: string
  status: string
  name: string
  error?: Error
  warning?: Error
}

export interface StepDetails {
  stepId: string
  status: string
  title: string
  subSteps: SubStep[]
}
interface StepBarFillerProps {
  step: any
  isFirst: boolean
  isLast: boolean
}

const colorMap = {
  [IconStatus.Success]: colors.green[80],
  [IconStatus.InProgress]: colors.blue[400],
  [IconStatus.Failed]: colors.red[80],
  [IconStatus.Warning]: colors.yellow[500],
}

const useStyles = makeStyles<Theme>(() => ({
  stepBar: (props: {
    step: StepDetails
    stepProgress: number
    isFirst: boolean
    isLast: boolean
  }) => ({
    height: '6px',
    width: '100%',
    background: colorMap[props.step.status],
    borderRadius: props.isFirst
      ? '0px 3px 0px 3px'
      : props.isLast
      ? '3px 0px 3px 0px'
      : '3px 3px 0px 0px',
  }),
}))

const StepBarFiller = ({ step, isFirst, isLast }: StepBarFillerProps) => {
  const calculatePercent = (currentVal, TotalVal) => (currentVal / TotalVal) * 100
  const stepProgress = useMemo(() => {
    const totalSteps = step?.subSteps?.length
    const completedSteps = step?.subSteps?.filter(
      (step) => step?.status === IconStatus.InProgress || step?.status === IconStatus.Success,
    )?.length
    const fillComplete = 100
    return step?.status === IconStatus.Failed || step?.status === IconStatus.Warning
      ? fillComplete
      : calculatePercent(completedSteps, totalSteps)
  }, [step?.subSteps, step?.status])

  const classes = useStyles({ step, stepProgress, isFirst, isLast })

  return <div className={classes.stepBar} />
}

export default StepBarFiller
