import { publicRoutes } from './publicRoutes'
import { kubernetesRoutes } from './kubernetesRoutes'
import { accountRoutes } from './accountRoutes'
import { openstackRoutes } from './openstackRoutes'

export const routes = {
  ...publicRoutes,
  ...accountRoutes,
  ...openstackRoutes,
  ...kubernetesRoutes,
}
