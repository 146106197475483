import { AppPlugins, pluginRoutePrefix } from 'app/constants'
import { IConfigMapsTabs } from 'k8s/components/resources/config-maps/selectors'
import { ISecretsDetailsPageTabs } from 'k8s/components/resources/secrets/model'
import {
  ICustomResourceDefinitionDetailsPageTabs,
  ICustomResourceDetailsPageTabs,
} from 'k8s/components/resources/custom-resource-definitions/model'
import { IStorageDetailsPageTabs } from 'k8s/components/resources/storage-classes/model'
import { IPersistentVolumeDetailsPageTabs } from 'k8s/components/resources/persistent-volume/model'
import { IDeployedAppDetailsPageTabs } from 'k8s/components/app-catalog/deployed-apps/model'
import { Route } from 'core/plugins/route'

const defaultParams = {
  plugin: AppPlugins.OpenStack,
  showClusterDropdown: 'true',
}

const metadata = {
  showGlobalFilters: true,
}

export const kubernetesRoutes = {
  kubernetes: {
    dashboard: {
      root: Route.register({
        url: `${pluginRoutePrefix}/kubernetes/clusters`,
        name: 'Clusters',
        id: 'clustersDashboard',
        breadcrumbs: new Map([['clusters', 'clustersDashboard']]),
        metadata,
        defaultParams: {
          plugin: AppPlugins.OpenStack,
        },
      }),
    },
    rbac: {
      roles: Route.register({
        url: `${pluginRoutePrefix}/rbac/roles`,
        id: 'rbacRolesList',
        breadcrumbs: new Map([['roles', 'rbacRolesList']]),
        metadata,
        defaultParams,
        name: 'RBAC:Roles:List',
      }),
      addRoles: Route.register({
        url: `${pluginRoutePrefix}/rbac/roles/add`,
        breadcrumbs: new Map([['roles', 'rbacRolesList']]),
        metadata,
        defaultParams,
        name: 'RBAC:Roles:Add',
      }),
      editRoles: Route.register({
        url: `${pluginRoutePrefix}/rbac/roles/edit/:id/cluster/:clusterId`,
        metadata,
        defaultParams,
        breadcrumbs: new Map([['roles', 'rbacRolesList']]),
        name: 'RBAC:Roles:Edit',
      }),
      clusterRoles: Route.register({
        url: `${pluginRoutePrefix}/rbac/cluster-roles`,
        id: 'rbacClusterRolesList',
        breadcrumbs: new Map([['cluster-roles', 'rbacClusterRolesList']]),
        metadata,
        defaultParams,
        name: 'RBAC:ClusterRoles:List',
      }),
      addClusterRoles: Route.register({
        url: `${pluginRoutePrefix}/rbac/cluster-roles/add`,
        breadcrumbs: new Map([
          ['cluster-roles', 'rbacClusterRolesList'],
          ['Add', 'rbacClusterRolesList'],
        ]),
        metadata,
        defaultParams,
        name: 'RBAC:ClusterRoles:Add',
      }),
      editClusterRoles: Route.register({
        url: `${pluginRoutePrefix}/rbac/cluster-roles/edit/:id/cluster/:clusterId`,
        breadcrumbs: new Map([
          ['cluster-roles', 'rbacClusterRolesList'],
          ['edit', 'rbacClusterRolesList'],
        ]),
        metadata,
        defaultParams,
        name: 'RBAC:ClusterRoles:Edit',
      }),
      roleBindings: Route.register({
        url: `${pluginRoutePrefix}/rbac/role-bindings`,
        id: 'rbacRoleBindingsList',
        breadcrumbs: new Map([['role-bindings', 'rbacRoleBindingsList']]),
        metadata,
        defaultParams,
        name: 'RBAC:RoleBindings:List',
      }),
      addRoleBindings: Route.register({
        url: `${pluginRoutePrefix}/rbac/role-bindings/add`,
        breadcrumbs: new Map([['role-bindings', 'rbacRoleBindingsList']]),
        metadata,
        defaultParams,
        name: 'RBAC:RoleBindings:Add',
      }),
      editRoleBindings: Route.register({
        url: `${pluginRoutePrefix}/rbac/role-bindings/edit/:id/cluster/:clusterId`,
        breadcrumbs: new Map([
          ['role-bindings', 'rbacRoleBindingsList'],
          ['edit', 'rbacRoleBindingsList'],
        ]),
        metadata,
        defaultParams,
        name: 'RBAC:RoleBindings:Edit',
      }),
      clusterRoleBindings: Route.register({
        url: `${pluginRoutePrefix}/rbac/cluster-role-bindings`,
        id: 'rbacClusterRoleBindingsList',
        breadcrumbs: new Map([['cluster-role-bindings', 'rbacClusterRoleBindingsList']]),
        metadata,
        defaultParams,
        name: 'RBAC:ClusterRoleBindings:List',
      }),
      addClusterRoleBindings: Route.register({
        url: `${pluginRoutePrefix}/rbac/cluster-role-bindings/add`,
        breadcrumbs: new Map([['cluster-role-bindings', 'rbacClusterRoleBindingsList']]),
        metadata,
        defaultParams,
        name: 'RBAC:ClusterRolesBindings:Add',
      }),
      editClusterRoleBindings: Route.register({
        url: `${pluginRoutePrefix}/rbac/cluster-role-bindings/edit/:id/cluster/:clusterId`,
        breadcrumbs: new Map([['cluster-role-bindings', 'rbacClusterRoleBindingsList']]),
        metadata,
        defaultParams,
        name: 'RBAC:ClusterRoleBindings:Edit',
      }),
    },
    manage: {
      overview: Route.register<{ cluster: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/manage/overview`,
        name: 'Capacity and Health',
        id: 'overview',
        breadcrumbs: new Map([['Overview', 'overview']]),
        defaultParams,
      }),
      upgradeStatus: Route.register<{ cluster: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/manage/overview/upgrade-status`,
        name: 'Cluster Upgrade Status Modal',
        id: 'overview',
        breadcrumbs: new Map([['overview', 'overview']]),
        defaultParams,
      }),
      capacityAndHealth: Route.register<{ cluster: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/manage/capacity-and-health`,
        name: 'Capacity and Health',
        id: 'capacityAndHealth',
        breadcrumbs: new Map([
          ['Overview', 'overview'],
          ['Nodes', 'capacityAndHealth'],
        ]),
        defaultParams,
      }),
      clusterUpgrade: Route.register<{ cluster: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/manage/upgrade`,
        name: 'Overview:Details',
        id: 'clusterUpgrade',
        breadcrumbs: new Map([
          ['Overview', 'overview'],
          [':cluster', 'overview'],
          ['Upgrade', 'clusterUpgrade'],
        ]),
        defaultParams,
      }),
      alerts: Route.register<{ cluster: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/manage/alerts`,
        name: 'Alerts',
        id: 'alerts',
        breadcrumbs: new Map([
          [':cluster', 'clusterOverview'],
          ['alerts', 'alerts'],
        ]),
        defaultParams,
      }),
    },
    accessControl: {
      roles: Route.register<{ cluster: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/roles`,
        name: 'Roles',
        id: 'rolesList',
        breadcrumbs: new Map([['Roles', 'rolesList']]),
        defaultParams,
      }),
      addRoles: Route.register<{ cluster: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/roles/add`,
        name: 'Roles:Add',
        breadcrumbs: new Map([['roles', 'rolesList']]),
        defaultParams,
      }),
      editRoles: Route.register<{ cluster: string; roleId: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/roles/:roleId/edit`,
        metadata,
        defaultParams,
        breadcrumbs: new Map([['roles', 'rolesList']]),
        name: 'Roles:Edit',
      }),
      roleBindings: Route.register<{ cluster: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/role-bindings`,
        name: 'Roles Bindings',
        id: 'roleBindingsList',
        breadcrumbs: new Map([['Role Bindings', 'roleBindingsList']]),
        defaultParams,
      }),
      addRoleBindings: Route.register<{ cluster: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/role-bindings/add`,
        breadcrumbs: new Map([['role-bindings', 'roleBindingsList']]),
        metadata,
        defaultParams,
        name: 'RoleBindings:Add',
      }),
      editRoleBindings: Route.register<{ cluster: string; id: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/role-bindings/:id/edit`,
        breadcrumbs: new Map([['role-bindings', 'roleBindingsList']]),
        metadata,
        defaultParams,
        name: 'RoleBindings:Edit',
      }),
      clusterRoles: Route.register<{ cluster: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/cluster-roles`,
        name: 'Cluster Roles',
        id: 'clusterRolesList',
        breadcrumbs: new Map([['Cluster Roles', 'clusterRolesList']]),
        defaultParams,
      }),
      addClusterRoles: Route.register({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/cluster-roles/add`,
        breadcrumbs: new Map([['Cluster Roles', 'clusterRolesList']]),
        metadata,
        defaultParams,
        name: 'ClusterRoles:Add',
      }),
      editClusterRoles: Route.register({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/cluster-roles/:id/edit`,
        breadcrumbs: new Map([['Cluster Roles', 'clusterRolesList']]),
        metadata,
        defaultParams,
        name: 'ClusterRoles:Edit',
      }),
      clusterRoleBindings: Route.register<{ cluster: string }>({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/cluster-role-bindings`,
        name: 'Cluster Role Bindings',
        id: 'clusterRoleBindingsList',
        breadcrumbs: new Map([['Cluster Role Bindings', 'clusterRoleBindingsList']]),
        defaultParams,
      }),
      addClusterRoleBindings: Route.register({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/cluster-role-bindings/add`,
        breadcrumbs: new Map([['Cluster Role Bindings', 'clusterRoleBindingsList']]),
        metadata,
        defaultParams,
        name: 'ClusterRolesBindings:Add',
      }),
      editClusterRoleBindings: Route.register({
        url: `${pluginRoutePrefix}/clusters/:cluster/access-control/cluster-role-bindings/:id/edit`,
        breadcrumbs: new Map([['Cluster Role Bindings', 'clusterRoleBindingsList']]),
        metadata,
        defaultParams,
        name: 'ClusterRoleBindings:Edit',
      }),
    },
    resources: {
      namespaces: {
        list: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/namespaces`,
          name: 'Namespaces',
          id: 'namespaces',
          breadcrumbs: new Map([['Namespaces', 'namespaces']]),
          defaultParams,
        }),
        add: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/namespaces/add`,
          name: 'Add Namespaces',
          id: 'addNamespaces',
          breadcrumbs: new Map([['Namespaces', 'namespaces']]),
          defaultParams,
        }),
      },
      configMaps: {
        list: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/config-maps`,
          name: 'Config Maps',
          id: 'configMaps',
          breadcrumbs: new Map([['Config Maps', 'configMaps']]),
          defaultParams,
        }),
        add: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/config-maps/add`,
          name: 'Add Config Maps',
          id: 'addConfigMaps',
          breadcrumbs: new Map([['Config Maps', 'configMaps']]),
          defaultParams,
        }),
        details: Route.register<{ cluster: string; id: string; tab?: IConfigMapsTabs }>({
          id: 'configMapDetails',
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/config-maps/:id/:tab`,
          breadcrumbs: new Map([
            ['Config Maps', 'configMaps'],
            [':id', 'configMapDetails'],
          ]),
          defaultParams: {
            ...defaultParams,
            tab: IConfigMapsTabs.Overview,
          },
          name: 'ConfigMaps:Details',
          tab: 'tab',
        }),
      },
      secrets: {
        list: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/secrets`,
          name: 'Secrets',
          id: 'secrets',
          breadcrumbs: new Map([['Secrets', 'secrets']]),
          defaultParams,
        }),
        add: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/secrets/add`,
          name: 'Add Secrets',
          id: 'addSecrets',
          breadcrumbs: new Map([['Secrets', 'secrets']]),
          defaultParams,
        }),
        details: Route.register<{
          cluster: string
          id: string
          tab?: ISecretsDetailsPageTabs
        }>({
          id: 'secretDetails',
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/secrets/:id/:tab`,
          breadcrumbs: new Map([
            ['Secrets', 'secrets'],
            [':id', 'secretDetails'],
          ]),
          defaultParams: {
            ...defaultParams,
            tab: ISecretsDetailsPageTabs.Overview,
          },
          name: 'Secrets:Details',
          tab: 'tab',
        }),
      },
      customResourceDefinitions: {
        list: Route.register({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/custom-resource-definitions`,
          id: 'customResourceDefinitionList',
          breadcrumbs: new Map([['Custom Resource Definitions', 'customResourceDefinitionList']]),
          defaultParams,
          name: 'CustomResourceDefinitions:List',
        }),
        add: Route.register({
          id: 'customResourceDefinitionAdd',
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/custom-resource-definitions/add`,
          breadcrumbs: new Map([
            ['Custom Resource Definitions', 'customResourceDefinitionList'],
            ['Add', 'customResourceDefinitionAdd'],
          ]),
          defaultParams,
          name: 'CustomResourceDefinitions:Add',
        }),
        details: Route.register<{
          cluster: string
          id: string
          tab?: ICustomResourceDefinitionDetailsPageTabs
        }>({
          id: 'customResourceDefinitionDetails',
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/custom-resource-definitions/:id/:tab`,
          breadcrumbs: new Map([
            ['Custom Resource Definitions', 'customResourceDefinitionList'],
            [':id', 'customResourceDefinitionDetails'],
          ]),
          defaultParams: {
            ...defaultParams,
            tab: ICustomResourceDefinitionDetailsPageTabs.Overview,
          },
          name: 'CustomResourceDefinitions:Details',
          tab: 'tab',
        }),
        customResources: Route.register<{
          cluster: string
          id: string
        }>({
          id: 'customResources',
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/custom-resource-definitions/:id/custom-resources`,
          breadcrumbs: new Map([
            ['Custom Resource Definitions', 'customResourceDefinitionList'],
            [':id', 'details'],
          ]),
          defaultParams,
          name: 'CustomResourceDefinitions:Details:CustomResources',
        }),
        customResourceAdd: Route.register<{
          cluster: string
          id: string
        }>({
          id: 'customResourceAdd',
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/custom-resource-definitions/:id/custom-resources/add`,
          breadcrumbs: new Map([
            ['Custom Resource Definitions', 'customResourceDefinitionList'],
            ['Custom Resources', 'customResources'],
            ['Add', 'customResourceAdd'],
          ]),
          defaultParams,
          name: 'CustomResourceDefinitions:Details:CustomResources:Add',
        }),
        customResourceDetails: Route.register<{
          cluster: string
          id: string
          name: string
          tab?: ICustomResourceDetailsPageTabs
        }>({
          id: 'customResourceDetails',
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/custom-resource-definitions/:id/custom-resources/:name/:tab`,
          breadcrumbs: new Map([
            ['Custom Resource Definitions', 'customResourceDefinitionList'],
            [':id', 'customResourceDefinitionDetails'],
            ['Custom Resources', 'customResources'],
            [':name', 'customResourceDetails'],
          ]),
          defaultParams: {
            ...defaultParams,
            tab: ICustomResourceDetailsPageTabs.Overview,
          },
          name: 'CustomResourceDefinitions:Details:CustomResources:Details',
          tab: 'tab',
        }),
      },
      storageClasses: {
        list: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/storage-classes`,
          name: 'Storage Classes',
          id: 'storageClasses',
          breadcrumbs: new Map([['Storage Classes', 'storageClasses']]),
          defaultParams,
        }),
        add: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/storage-classes/add`,
          name: 'Add Storage Classes',
          id: 'addStorageClasses',
          breadcrumbs: new Map([['Storage Classes', 'storageClasses']]),
          defaultParams,
        }),
        details: Route.register<{ cluster: string; id: string; tab?: IStorageDetailsPageTabs }>({
          id: 'storageClassesDetails',
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/storage-classes/:id/:tab`,
          breadcrumbs: new Map([
            ['Storage Classes', 'storageClasses'],
            [':id', 'storageClassesDetails'],
          ]),
          defaultParams: {
            ...defaultParams,
            tab: IStorageDetailsPageTabs.Overview,
          },
          name: 'StorageClasses:Details',
          tab: 'tab',
        }),
      },
      persistentVolumeClaims: {
        list: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/persistent-volume-claims`,
          name: 'Persistent Volume Claims',
          id: 'persistentVolumeClaimsList',
          breadcrumbs: new Map([['Persistent Volume Claims', 'persistentVolumeClaimsList']]),
          defaultParams,
        }),
        add: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/persistent-volume-claims/add`,
          name: 'Persistent Volume Claims',
          id: 'addPersistentVolumeClaims',
          breadcrumbs: new Map([['Persistent Volume Claims', 'persistentVolumeClaimsList']]),
          defaultParams,
        }),
        details: Route.register<{ cluster: string; id: string; tab?: string }>({
          id: 'persistentVolumeClaimDetails',
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/persistent-volume-claims/:id/:tab`,
          breadcrumbs: new Map([
            ['Persistent Volume Claims', 'persistentVolumeClaimsList'],
            [':id', 'persistentVolumeClaimDetails'],
          ]),
          defaultParams: {
            ...defaultParams,
            tab: 'overview',
          },
          name: 'Resources:PersistentVolumeClaims:Details',
        }),
      },
      persistentVolumes: {
        list: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/persistent-volumes`,
          name: 'Persistent Volumes',
          id: 'persistentVolumes',
          breadcrumbs: new Map([['Persistent Volumes', 'persistentVolumes']]),
          defaultParams,
        }),
        add: Route.register<{ cluster: string }>({
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/persistent-volumes/add`,
          name: 'Add Persistent Volumes',
          id: 'addPersistentVolumes',
          breadcrumbs: new Map([['Persistent Volumes', 'persistentVolumes']]),
          defaultParams,
        }),
        details: Route.register<{
          cluster: string
          id: string
          tab?: IPersistentVolumeDetailsPageTabs
        }>({
          id: 'persistentVolumesDetails',
          url: `${pluginRoutePrefix}/clusters/:cluster/resources/persistent-volumes/:id/:tab`,
          breadcrumbs: new Map([
            ['Persistent Volumes', 'persistentVolumes'],
            [':id', 'persistentVolumesDetails'],
          ]),
          defaultParams: {
            ...defaultParams,
            tab: IPersistentVolumeDetailsPageTabs.Overview,
          },
          name: 'PersistentVolumes:Details',
          tab: 'tab',
        }),
      },
    },
    repositories: {
      list: Route.register({
        url: `${pluginRoutePrefix}/apps/repositories`,
        id: 'repositoriesList',
        breadcrumbs: new Map([
          ['apps', null],
          ['helm-repositories', 'repositoriesList'],
        ]),
        metadata,
        defaultParams,
        name: 'Repositories:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/apps/repositories/add`,
        breadcrumbs: new Map([
          ['apps', null],
          ['helm-repositories', 'repositoriesList'],
          ['add', 'repositoriesList'],
        ]),
        metadata,
        defaultParams,
        name: 'Repositories:Add',
      }),
      edit: Route.register({
        url: `${pluginRoutePrefix}/apps/repositories/edit/:id`,
        breadcrumbs: new Map([
          ['apps', null],
          ['helm-repositories', 'repositoriesList'],
          ['edit', 'repositoriesList'],
        ]),
        metadata,
        defaultParams,
        name: 'Repositories:Edit',
      }),
    },
    apps: {
      list: Route.register({
        url: `${pluginRoutePrefix}/apps/app-catalog`,
        id: 'appCatalogList',
        breadcrumbs: new Map([
          ['apps', null],
          ['app-catalog', 'appCatalogList'],
        ]),
        name: 'Apps:List',
        metadata,
        defaultParams,
      }),
      deploy: Route.register({
        url: `${pluginRoutePrefix}/apps/app-catalog/deploy/:repository/:name`,
        name: 'Apps:Deploy',
        breadcrumbs: new Map([
          ['apps', null],
          ['app-catalog', 'appCatalogList'],
        ]),
        metadata,
        defaultParams,
      }),
      detail: Route.register({
        url: `${pluginRoutePrefix}/apps/:clusterId/:release/:id`,
        id: 'appCatalogDetails',
        breadcrumbs: new Map([
          ['apps', null],
          [':id', 'appCatalogDetails'],
        ]),
        metadata,
        defaultParams,
        name: 'Apps:Details',
      }),
      deployed: {
        list: Route.register({
          url: `${pluginRoutePrefix}/apps/deployed-apps`,
          id: 'deployedAppsList',
          breadcrumbs: new Map([
            ['apps', null],
            ['deployed-apps', 'deployedAppsList'],
          ]),
          metadata,
          defaultParams,
          name: 'Apps:Deployed',
        }),
        edit: Route.register({
          url: `${pluginRoutePrefix}/apps/:clusterId/:namespace/deployed-apps/edit/:repository/:chart/:name`,
          name: 'Apps:Edit',
          breadcrumbs: new Map([
            ['apps', null],
            ['deployed-apps', 'deployedAppsList'],
            ['edit', 'deployedAppsList'],
          ]),
          metadata,
          defaultParams,
        }),
        details: Route.register<{
          clusterId: string
          namespace: string
          repository: string
          name: string
          tab?: IDeployedAppDetailsPageTabs
        }>({
          id: 'deployedAppDetails',
          url: `${pluginRoutePrefix}/apps/:clusterId/:namespace/deployed-apps/:repository/:chart/:name/:tab`,
          breadcrumbs: new Map([
            ['apps', null],
            ['deployed-apps', 'deployedAppsList'],
            [':name', 'deployedAppDetails'],
          ]),
          defaultParams: {
            ...defaultParams,
            tab: 'overview',
          },
          name: 'Apps:Deployed:Details',
          tab: 'tab',
        }),
      },
    },
    nodes: {
      byohHosts: Route.register({
        url: `${pluginRoutePrefix}/kubernetes/nodes/byoh-hosts`,
        id: 'byohHosts',
        breadcrumbs: new Map([['BYOH Hosts', 'byohHosts']]),
        metadata,
        defaultParams: {
          plugin: AppPlugins.OpenStack,
        },
        name: 'Nodes:BYOH Hosts',
      }),
    },
  },
}
