import React, { useCallback } from 'react'
import Menu from 'core/elements/menu/Menu'
import MenuItem from 'core/elements/menu/MenuItem'
import { routes } from 'core/utils/routes'
import Theme from 'core/themes/model'
import generateTestId from 'utils/test-helpers'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/core/styles'
import { isAdminRole } from 'k8s/util/helpers'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { userAccountPrefix } from 'app/constants'
import Tooltip from 'pf9-ui-components/built/elements/tooltip'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    marginRight: `${theme.spacing(-1)}px`,
  },
  dropdownContainer: {
    padding: 16,
    paddingBottom: 0,
    display: 'grid',
    gap: 16,
    // gridTemplateRows: 'max-content 1fr max-content',
  },
  dropdownLinks: {
    display: 'grid',
    gridAutoRows: 48,
  },
  settings: {
    cursor: 'pointer',
    width: 36,
    height: 36,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    overflow: 'hidden',

    '&:hover': {
      borderRadius: '50%',
      backgroundColor: theme.components.iconButton.background,
      color: theme.components.iconButton.color,
      transition: 'all .2s ease',
    },
  },
  seperator: {
    height: 1,
    backgroundColor: theme.components.iconButton.border,
    position: 'absolute',
    bottom: 55,
    right: 0,
    left: 0,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}))

export const MenuItems = [
  {
    name: 'User Settings',
    path: `${userAccountPrefix}/settings/profile`,
    icon: 'users-cog',
  },
  {
    name: 'API Access',
    icon: 'key',
    path: `${userAccountPrefix}/api-access`,
  },
  {
    name: 'Tenants & Users',
    path: `${userAccountPrefix}/access/users`,
    icon: 'users',
    requiredRoles: 'admin',
  },
  {
    name: 'Enterprise SSO',
    path: `${userAccountPrefix}/sso/configure`,
    icon: 'address-book',
    requiredRoles: 'admin',
  },
  {
    name: 'Custom Theme',
    path: `${userAccountPrefix}/theme`,
    icon: 'palette',
    requiredRoles: 'admin',
  },
]

const menuOffset = {
  vertical: 8,
  horizontal: -38, // diameter is 36 with 1px boder on each side
}

const Settings = ({ className }: { className?: string }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const showMenuItem = useCallback(
    (requiredRole: string) => (requiredRole === 'admin' ? isAdminRole(session) : true),
    [session],
  )

  const Seperator = () => <div className={classes.seperator} />

  return (
    <div className={classes.container}>
      <Menu
        id="user-menu"
        origin="top right"
        offset={menuOffset}
        anchor={
          <div className={classes.settings} onClick={handleClick}>
            <Tooltip
              message="Settings"
              align={{ vertical: 'bottom', horizontal: 'middle' }}
              offset={{ vertical: 12, horizontal: 0 }}
            >
              <FontAwesomeIcon size="lg">cog</FontAwesomeIcon>
            </Tooltip>
          </div>
        }
        open={Boolean(anchorEl)}
        onClose={handleClose}
        maxWidth={232}
        // maxHeight={64}
      >
        <div className={classes.dropdownContainer}>
          <div className={classes.dropdownLinks}>
            {MenuItems.map((item) =>
              showMenuItem(item?.requiredRoles) ? (
                <MenuItem
                  src={item?.path}
                  onClick={handleClose}
                  data-testid={generateTestId('admin', item?.name)}
                  icon={item.icon}
                  component="p"
                >
                  {item?.name}
                </MenuItem>
              ) : null,
            )}
            <Seperator />
            <MenuItem
              src={routes.userSettings.root.path()}
              onClick={handleClose}
              data-testid={generateTestId('admin', 'settings')}
              icon="cogs"
              component="p"
            >
              All Settings
            </MenuItem>
          </div>
        </div>
      </Menu>
    </div>
  )
}

export default Settings
