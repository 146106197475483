import { AppPlugins, pluginRoutePrefix } from 'app/constants'
import { Route } from 'core/plugins/route'
import { ITenantDetailsPageTabs } from 'account/components/userManagement/tenants/model'

export const accountRoutes = {
  accountStatus: {
    root: Route.register({
      url: `${pluginRoutePrefix}/status`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
      name: 'AccountStatus:Root',
      id: 'accountStatus',
      breadcrumbs: new Map([['Account Status', 'accountStatus']]),
    }),
  },
  userSettings: {
    root: Route.register({
      url: `${pluginRoutePrefix}/settings/:tab`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
        tab: 'profile',
      },
      name: 'UserSettings:Root',
      id: 'userSettings',
      breadcrumbs: new Map([['User Settings', 'userSettings']]),
    }),
  },
  sso: {
    root: Route.register({
      url: `${pluginRoutePrefix}/sso/:tab`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
        tab: 'configure',
      },
      name: 'SsoManagement:Root',
      id: 'ssoManagement',
      breadcrumbs: new Map([['SSO Management', 'ssoManagement']]),
    }),
    addGroup: Route.register({
      url: `${pluginRoutePrefix}/sso/groups/add`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
      name: 'SsoManagement:Group:Add',
      id: 'addGroup',
      breadcrumbs: new Map([
        ['SSO Management', 'sso'],
        ['Add Group', 'addGroup'],
      ]),
    }),
    editGroup: Route.register({
      url: `${pluginRoutePrefix}/sso/groups/edit/:id`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
      name: 'SsoManagement:Group:Edit',
      id: 'editGroup',
      breadcrumbs: new Map([
        ['SSO Management', 'sso'],
        ['Edit Group', 'editGroup'],
      ]),
    }),
  },
  userManagement: {
    domains: Route.register({
      url: `${pluginRoutePrefix}/access/domains`,
      name: 'Domains',
      id: 'domains',
      breadcrumbs: new Map([['domains', 'domains']]),
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
    }),
    addDomain: Route.register({
      url: `${pluginRoutePrefix}/access/domains/add`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
      breadcrumbs: new Map([
        ['domains', 'domains'],
        ['add', 'domains'],
      ]),
      name: 'UserManagement:Domains:Add',
    }),
    editDomain: Route.register({
      url: `${pluginRoutePrefix}/access/domains/edit`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
      breadcrumbs: new Map([
        ['domains', 'domains'],
        ['edit', 'domains'],
      ]),
      name: 'UserManagement:Domains:Edit',
    }),
    tenants: Route.register({
      url: `${pluginRoutePrefix}/access/tenants`,
      name: 'Tenants',
      id: 'tenants',
      breadcrumbs: new Map([['tenants', 'tenants']]),
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
    }),
    addTenant: Route.register({
      url: `${pluginRoutePrefix}/access/tenants/add`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
      breadcrumbs: new Map([
        ['tenants', 'tenants'],
        ['add', 'tenants'],
      ]),
      name: 'UserManagement:Tenants:Add',
    }),

    tenantDetails: Route.register<{
      id: string
      tab?: ITenantDetailsPageTabs
    }>({
      url: `${pluginRoutePrefix}/access/tenants/:id/:tab`,
      name: 'Tenants:Details',
      id: 'tenantDetails',
      breadcrumbs: new Map([
        ['tenants', 'tenants'],
        [':id', 'tenantDetails'],
      ]),
      defaultParams: {
        plugin: AppPlugins.MyAccount,
        tab: 'overview',
      },
      tab: 'tab',
    }),
    users: Route.register({
      url: `${pluginRoutePrefix}/access/users`,
      name: 'Users',
      id: 'users',
      breadcrumbs: new Map([['users', 'users']]),
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
    }),
    addUser: Route.register({
      url: `${pluginRoutePrefix}/access/users/add`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
      breadcrumbs: new Map([
        ['users', 'users'],
        ['add', 'users'],
      ]),
      name: 'UserManagement:User:Add',
    }),
    roles: Route.register({
      url: `${pluginRoutePrefix}/access/roles`,
      name: 'Roles',
      id: 'roles',
      breadcrumbs: new Map([['roles', 'roles']]),
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
    }),
    groups: Route.register({
      url: `${pluginRoutePrefix}/access/groups`,
      name: 'Groups',
      id: 'groups',
      breadcrumbs: new Map([['groups', 'groups']]),
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
    }),
    addGroup: Route.register({
      url: `${pluginRoutePrefix}/access/groups/add`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
      breadcrumbs: new Map([
        ['groups', 'groups'],
        ['add', 'groups'],
      ]),
      name: 'UserManagement:Group:Add',
    }),
  },
  customTheme: Route.register({
    url: `${pluginRoutePrefix}/theme`,
    id: 'customTheme',
    defaultParams: {
      plugin: AppPlugins.MyAccount,
    },
    name: 'CustomTheme',
    breadcrumbs: new Map([['Custom Theme', 'customTheme']]),
  }),
  apiAccess: Route.register({
    url: `${pluginRoutePrefix}/api-access`,
    name: 'ApiAccess:List',
    id: 'apiAccessList',
    breadcrumbs: new Map([['API-access', 'apiAccessList']]),
    defaultParams: {
      plugin: AppPlugins.MyAccount,
    },
  }),
}
