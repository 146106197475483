import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
} from 'react'
import { indexBy, prop, pluck, path } from 'ramda'
import { emptyArr, isNilOrEmpty } from 'utils/fp'
import useReactRouter from 'use-react-router'
import useToggler from 'core/hooks/useToggler'
import { pathJoin } from 'utils/misc'
import CreateButton from 'core/components/buttons/CreateButton'
import Grid from 'core/elements/grid'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { GridProps } from 'core/elements/grid/Grid'
import DeleteAction from 'core/actions/DeleteAction'
import { IDataKeys } from 'k8s/datakeys.model'
import GridSearchFilter from 'core/elements/grid/GridSearchFilter'
import useGridFiltering, { GridGlobalFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import { ArrayElement } from 'core/actions/Action'
import GridDefaultDeleteButton from 'core/elements/grid/buttons/GridDefaultDeleteButton'
import GridDefaultActionButton from 'core/elements/grid/buttons/GridDefaultActionButton'
import {
  BatchActionButtonProps,
  GridBatchActionSpec,
} from 'core/elements/grid/hooks/useGridSelectableRows'
import {
  HeaderTitlePortal,
  HeaderPrimaryActionPortal,
  HeaderRefreshPortal,
} from 'core/elements/header/portals'
import Breadcrumbs from 'core/elements/breadcrumbs'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import { isReadonlyUser } from 'core/utils/helpers'
import Button from 'core/elements/button'
import Tooltip from 'pf9-ui-components/built/elements/tooltip'
import ViewModeToggle, { ViewMode } from 'core/components/ViewModeToggle'
import CardViewContainer from './CardViewContainer'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'

interface SearchFunctionProps<T> {
  property: string
  searchFn: (value: keyof T | string, searchString: string, item?: any) => boolean
}

export interface ListContainerProps<D extends keyof IDataKeys, T = ArrayElement<IDataKeys[D]>>
  extends GridProps<T> {
  dataKey?: DataKeys
  searchTargets: Array<keyof T | string>
  searchFunctions?: SearchFunctionProps<T>[]
  nameProp?: keyof T

  addText?: string
  addUrl?: string | { (selectedItem: T, id: string): string }
  addCond?: () => boolean
  AddButtonComponent?: FC<BatchActionButtonProps<T>>
  AddDialogComponent?: FC<
    PropsWithChildren<{
      onClose: () => void
    }>
  >
  disableAddButton?: boolean
  disabledAddButtonInfo?: string

  editText?: string
  editUrl?: string | { (selectedItem: T, id: string): string }
  editCond?: (selectedItems: T[]) => boolean
  editDisabledInfo?: string
  EditButtonComponent?: FC<BatchActionButtonProps<T>>
  EditDialogComponent?: FC<
    PropsWithChildren<{
      onClose: () => void
      rows: T[]
    }>
  >

  deleteText?: string
  deleteAction?: DeleteAction<D>
  deleteCond?: (selectedItems: T[]) => boolean
  deleteDisabledInfo?: string
  DeleteButtonComponent?: FC<BatchActionButtonProps<T>>
  DeleteDialogComponent?: FC<
    PropsWithChildren<{
      onClose: () => void
      rows: T[]
      onSuccess?: () => void
    }>
  >
  showBreadcrumbs?: boolean
  extraHeaderContent?: ReactNode
  extraToolbarContent?: ReactNode
  label?: string
  getParamsUpdater?: (...keys: string[]) => (...values: unknown[]) => void
  tooltip?: ReactNode
  dropdownFilters?: any[]
  isReadOnly?: boolean
  hideCheckboxes?: boolean
  iconOnly?: boolean
  hideRefreshButtonOnTop?: boolean
  CardComponent?: FC<
    PropsWithChildren<{
      item: any
    }>
  >
  HeaderActions?: ReactNode
}

export const getDeleteConfirmText = (selectedItems, nameProp = 'name') => {
  if (isNilOrEmpty(selectedItems)) {
    return ''
  }
  const pluckName = pluck(nameProp)
  const selectedNames = pluckName(selectedItems).join(', ')
  return `This will permanently delete the following: ${selectedNames}`
}

export default function ListContainer<D extends keyof IDataKeys, T = ArrayElement<IDataKeys[D]>>(
  props: ListContainerProps<D, T>,
) {
  const {
    dataKey,
    uniqueIdentifier,
    searchTargets,
    searchFunctions,
    nameProp = 'name',
    batchActions = [],
    dropdownBatchActions = [],
    globalFilters: customGlobalFilters = [],
    dropdownFilters = [],

    addText = 'Add',
    AddButtonComponent = CreateButton,
    addUrl,
    addCond,
    AddDialogComponent,
    disableAddButton = false,
    disabledAddButtonInfo = '',

    editText = 'Edit',
    editDisabledInfo,
    EditButtonComponent = GridDefaultActionButton,
    EditDialogComponent,
    editUrl,
    editCond,

    deleteText = 'Delete',
    deleteAction,
    deleteCond,
    deleteDisabledInfo,
    DeleteButtonComponent = GridDefaultDeleteButton,
    DeleteDialogComponent,

    showBreadcrumbs = true,
    extraHeaderContent,
    loading,
    label = entityNamesByKey[dataKey],

    getParamsUpdater,
    hideCheckboxes = false,
    // By Default isReadOnly is set to true if the user is readonly and you can override it using the isReadOnly prop
    isReadOnly = isReadonlyUser(),
    disableRowSelection = false,
    multiSelection,
    onRefresh,
    iconOnly,
    hideRefreshButtonOnTop = true,
    visibleColumns,

    data,
    CardComponent,
    HeaderActions,
  } = props
  const classes = useStyles()
  const url = new URL(window.location.href)
  const selectedView = url.searchParams.get('view') as ViewMode | null

  const { history } = useReactRouter()
  const deletePromise = useRef<(value?: unknown) => void | Promise<void>>(undefined)
  const [showingConfirmDialog, toggleConfirmDialog] = useToggler()
  const [showingEditDialog, toggleEditDialog] = useToggler()
  const [showingAddDialog, toggleAddDialog] = useToggler()
  const [selectedItems, setSelectedItems] = useState<T[]>(emptyArr)
  const [view, setView] = useState<ViewMode>(selectedView || ViewMode.LIST)
  const [filteredRows, setFilteredRows] = useState([])

  const { update: handleRemove, updating: deleting } = deleteAction
    ? useUpdateAction(deleteAction)
    : { update: null, updating: false }

  // @ts-ignore
  const globalFilters = useMemo<Array<GridGlobalFilterSpec<T, Record<string, unknown>>>>(
    () => [
      {
        key: 'search',
        equalityComparerFn: (item, value) => {
          const searchFunctionsByProperty = searchFunctions
            ? indexBy(prop('property'), searchFunctions)
            : {}
          const filteredSearchTargets = searchTargets?.filter((target: string) => {
            const targetBase = target?.split('.')[0]
            return visibleColumns?.includes(targetBase)
          })
          return !!filteredSearchTargets.find((key) => {
            // @ts-ignore
            if (searchFunctions && searchFunctionsByProperty[key]) {
              // @ts-ignore
              const searchFn = searchFunctionsByProperty[key]?.searchFn
              return searchFn(item[String(key)], value, item)
            }
            return String(
              item.hasOwnProperty(key) ? item[String(key)] : path(String(key).split('.'), item),
            )
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase())
          })
        },
        FilterComponent: GridSearchFilter,
      } as GridGlobalFilterSpec<T, Record<string, unknown>, 'search', string>,
      ...customGlobalFilters,
    ],
    [searchTargets, searchFunctions, visibleColumns],
  )

  const deleteConfirmText = useMemo(() => getDeleteConfirmText(selectedItems, String(nameProp)), [
    selectedItems,
  ])

  const allBatchActions = useMemo(() => {
    const defaultBatchActions: GridBatchActionSpec<T>[] = []
    const deleteBatchAction: GridBatchActionSpec<T>[] = []

    if (EditDialogComponent || editUrl) {
      defaultBatchActions.push({
        label: editText,
        cond: editCond,
        disabledTooltip: editDisabledInfo,
        handleAction: async (selectedItems) => {
          if (editUrl) {
            const [selectedRow] = selectedItems
            const selectedId = String(selectedRow[uniqueIdentifier])
            const urlResult =
              typeof editUrl === 'function'
                ? editUrl(selectedRow, selectedId)
                : pathJoin(editUrl, selectedId)
            history.push(urlResult)
            return
          }
          setSelectedItems(selectedItems)
          toggleEditDialog()
        },
        BatchActionButton: EditButtonComponent,
        iconOnly,
        tooltip: iconOnly ? 'Edit' : null,
      })
    }

    if (handleRemove) {
      deleteBatchAction.push({
        label: deleteText,
        cond: deleteCond,
        disabledTooltip: deleteDisabledInfo,
        keepRowsSelected: false,
        handleAction: async (selectedItems) => {
          setSelectedItems(selectedItems)
          toggleConfirmDialog()
          // Stash the promise resolver, so it can be used to resolve later
          // in response to a user interaction (delete confirmation).
          // Not sure why this stopped working.. commenting it out
          // return new Promise((resolve) => {
          //   deletePromise.current = resolve
          // })
        },
        BatchActionButton: DeleteButtonComponent,
        iconOnly,
        tooltip: iconOnly ? 'Delete' : null,
      })
    }

    return [...defaultBatchActions, ...batchActions, ...deleteBatchAction]
  }, [uniqueIdentifier, handleRemove, batchActions])

  const handleDeleteConfirm = useCallback(async () => {
    toggleConfirmDialog()
    await Promise.all(selectedItems.map(handleRemove))
    await deletePromise.current()
  }, [selectedItems, handleRemove])

  const onDeleteSuccess = useCallback(async () => {
    await deletePromise.current()
  }, [])

  const handleAdd = () => {
    trackEvent(`Clicked ${addText}`)
    if (addUrl) {
      history.push(addUrl)
    } else if (AddDialogComponent) {
      toggleAddDialog()
    }
  }

  const addEnabled = useMemo(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    const didAddCondPass = !!addCond ? addCond() : true
    const isCustomAddButton = AddButtonComponent !== CreateButton
    return (!!AddDialogComponent || !!addUrl || isCustomAddButton) && didAddCondPass && !isReadOnly
  }, [addCond, isReadOnly, AddButtonComponent, AddDialogComponent, addUrl])

  const shouldRenderCardView = useMemo(() => CardComponent && view === ViewMode.CARD, [
    CardComponent,
    view,
  ])

  return (
    <>
      {showBreadcrumbs && (
        <HeaderTitlePortal>
          <Breadcrumbs />
        </HeaderTitlePortal>
      )}
      {AddDialogComponent && showingAddDialog && (
        <AddDialogComponent onClose={toggleAddDialog}>{addText}</AddDialogComponent>
      )}
      {EditDialogComponent && showingEditDialog && (
        <EditDialogComponent onClose={toggleEditDialog} rows={selectedItems}>
          {editText}
        </EditDialogComponent>
      )}
      {DeleteDialogComponent && showingConfirmDialog && (
        <DeleteDialogComponent
          onClose={toggleConfirmDialog}
          rows={selectedItems}
          onSuccess={onDeleteSuccess}
        >
          {deleteText}
        </DeleteDialogComponent>
      )}
      {!DeleteDialogComponent && handleRemove && showingConfirmDialog && (
        <ConfirmationDialog
          open={showingConfirmDialog}
          text={deleteConfirmText}
          onCancel={toggleConfirmDialog}
          onConfirm={handleDeleteConfirm}
        />
      )}
      {onRefresh && !hideRefreshButtonOnTop ? (
        <HeaderRefreshPortal>
          <Button variant="secondary" onClick={onRefresh} icon="sync">
            Refresh
          </Button>
        </HeaderRefreshPortal>
      ) : null}
      <HeaderPrimaryActionPortal>
        {extraHeaderContent}
        {addEnabled && (
          <Tooltip
            message={disableAddButton ? disabledAddButtonInfo : ''}
            align={{
              vertical: 'middle',
              horizontal: 'left',
            }}
          >
            <AddButtonComponent disabled={disableAddButton} onClick={handleAdd}>
              {addText}
            </AddButtonComponent>
          </Tooltip>
        )}
      </HeaderPrimaryActionPortal>

      <div className={classes.headerPortals}>
        <div className={classes.viewModeToggleContainer}>
          {CardComponent && <ViewModeToggle view={view} onViewChange={setView} />}
          {shouldRenderCardView && (
            <SearchComponentForCardView
              data={data}
              globalFilters={globalFilters}
              uniqueIdentifier={uniqueIdentifier}
              setFilteredRows={setFilteredRows}
            />
          )}
        </div>
        {HeaderActions && <div className={classes.headerActions}>{HeaderActions}</div>}
      </div>

      {shouldRenderCardView ? (
        <CardViewContainer
          data={filteredRows}
          CardComponent={CardComponent}
          uniqueIdentifier={uniqueIdentifier}
        />
      ) : (
        <Grid<T>
          {...props}
          multiSelection={!isReadOnly ? multiSelection : false}
          isReadOnly={isReadOnly}
          label={label}
          globalFilters={globalFilters}
          dropdownFilters={dropdownFilters}
          batchActions={allBatchActions}
          dropdownBatchActions={dropdownBatchActions}
          loading={loading || deleting}
          onSortChange={getParamsUpdater && getParamsUpdater('orderBy', 'orderDirection')}
          onRowsPerPageChange={getParamsUpdater && getParamsUpdater('rowsPerPage')}
          onColumnsChange={getParamsUpdater && getParamsUpdater('visibleColumns', 'columnsOrder')}
          hideCheckboxes={hideCheckboxes}
          disableRowSelection={disableRowSelection || isReadOnly}
        />
      )}
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  headerPortals: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  viewModeToggleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  itemsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filterComponent: {
    background: theme.palette.common.white,
    padding: '2px 120px 2px 8px',
    border: `1px solid ${theme.palette.grey[200]}`,
    '& input': {
      width: '210px',
    },
  },
}))

/**
 * @summary Search component for card view
 * @param
 */
const SearchComponentForCardView = <T extends Record<string, any>>({
  data,
  globalFilters,
  uniqueIdentifier,
  setFilteredRows,
}: {
  data: T[]
  globalFilters: any[]
  uniqueIdentifier: string | number | symbol
  setFilteredRows: (rows: Array<any>) => void
}) => {
  const classes = useStyles()
  const rows = data.map((item) => ({
    item,
    key: String(item[uniqueIdentifier as string]),
    getCells: () => Object.values(item),
  }))
  const [filteredRows, filteringProps] = useGridFiltering(rows, {
    globalFilters,
  })

  // Update filtered rows when filteredRows changes
  useEffect(() => {
    setFilteredRows(filteredRows)
  }, [filteredRows])

  return (
    <>
      {filteringProps?.globalFilters.map(
        ({ key, filterValue, filterValues, updateFilterValue, FilterComponent, ...rest }) => (
          <div className={classes.filterComponent}>
            <FilterComponent
              key={String(key)}
              value={filterValue}
              filterValues={filterValues}
              onChange={updateFilterValue}
              {...rest}
            />
          </div>
        ),
      )}
    </>
  )
}
