import { makeStyles } from '@material-ui/styles'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import StepBarFiller from './StepBarFiller'
import React from 'react'

interface StepHorizontalBarProps {
  steps: any[]
}

const useStyles = makeStyles<Theme>((theme) => ({
  stepBarContainer: {
    display: 'flex',
  },
  stepBar: {
    height: '6px',
    width: '100%',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: 'none',
    background: theme.palette.common.white,
    marginRight: '5px',
    '&:last-child': {
      marginRight: 0,
      borderRight: 'none',
    },
    borderRadius: '4px 4px 0px 0px',
    '&:first-child': {
      borderLeft: 'none',
      borderRadius: '0px 4px 0px 4px',
    },
    // '&:last-child': {
    //   borderRadius: '4px 0px 4px 0px',
    // },
  },
}))

const StepHorizontalBar = ({ steps }: StepHorizontalBarProps) => {
  const classes = useStyles()

  return (
    <div className={classes.stepBarContainer}>
      {steps?.map((step, index) => (
        <div className={classes.stepBar} key={step.stepId}>
          <StepBarFiller step={step} isFirst={index === 0} isLast={index === steps.length - 1} />
        </div>
      ))}
    </div>
  )
}

export default StepHorizontalBar
