import { makeStyles } from '@material-ui/styles'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import clsx from 'clsx'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { IRouterLink } from 'core/plugins/model'
import { matchesCurrentPath } from 'core/plugins/route-helpers'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import Theme from 'core/themes/model'
import { prop } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import generateTestId from 'utils/test-helpers'
import NavMenu from '../menu/NavMenu'
import NavItem from './NavItem'
import { motion } from 'framer-motion'
import { AppPlugins } from 'app/constants'

interface Props extends IRouterLink {
  isActive?: boolean
  tooltip?: boolean
  drawerOpen?: boolean
  menuOffset?: { vertical: number; horizontal: number }
  activeDisplayType?: 'background' | 'bar'
  className?: string
  disabled?: boolean
  info?: string
  nestingLevel?: number
  k8sClusterAvailable?: boolean
}

const processName = (name) => {
  if (!isAdmin()) {
    return name === 'Physical Networks' ? 'Networks' : name
  }
  return name
}

export default function NavSection({
  name,
  link,
  nestedLinks = undefined,
  icon,
  menuOffset = undefined,
  drawerOpen = false,
  isActive = false,
  disabled,
  info,
  nestingLevel = 1,
  k8sClusterAvailable,
}: Props) {
  const [open, setOpen] = useState(!!isActive)
  const [menuOpen, setMenuOpen] = useState(false)
  const { currentPath, currentPluginId } = usePluginRouter()
  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { virtualNetworking, features } = session
  const filteredNestedLinks = nestedLinks?.filter((link) => {
    return link?.requiredFeatures ? link.requiredFeatures(features) : true
  })

  // Open the section if the section is active
  useEffect(() => {
    setOpen(isActive)
  }, [isActive])

  const handleMenuOpen = () => {
    if (drawerOpen || !filteredNestedLinks?.length) return
    setMenuOpen(true)
  }

  const onNavItemClick = (event) => {
    // prevent from triggering click for nav item when the drawer is closed.
    // This is to prevent the nav item url from changing when the drawer is closed
    if (!filteredNestedLinks?.length) {
      return
    }

    if (!drawerOpen) {
      event.preventDefault()
      return
    }

    // Toggle the open state for nav item - open to close and vice versa
    setOpen((open) => !open)
  }

  const innerNested = nestingLevel === 2
  const isAccountPlugin = currentPluginId === AppPlugins.MyAccount
  const classes = useStyles({ isActive, open, drawerOpen, innerNested, isAccountPlugin })
  const navSection = (
    <div className={classes.navHeader} onClick={drawerOpen ? undefined : handleMenuOpen}>
      <NavItem
        name={name}
        link={link}
        isActive={isActive}
        drawerOpen={drawerOpen}
        tooltip={info}
        icon={icon}
        onClick={onNavItemClick}
        variant="sidenav1"
        tooltipProps={{ className: classes.navItemContainer }}
        disabled={disabled}
      />
      {filteredNestedLinks?.length && (
        <FontAwesomeIcon solid size="xs" className={classes.navIcon} onClick={() => setOpen(!open)}>
          chevron-down
        </FontAwesomeIcon>
      )}
    </div>
  )

  return (
    <>
      <NavMenu
        title={name}
        icon={icon}
        data-testid={generateTestId('nav-section', 'menu')}
        nestedLinks={filteredNestedLinks}
        onClose={() => setMenuOpen(false)}
        open={menuOpen}
        offset={menuOffset}
        origin="left top"
        anchor={navSection}
        disabled={disabled}
        drawerOpen={drawerOpen}
      />
      {filteredNestedLinks?.length && (
        // @ts-ignore
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{
            height: drawerOpen && open ? 'auto' : 0,
            opacity: drawerOpen && open ? 1 : 0,
          }}
          transition={{
            duration: 0.8,
            ease: [0.04, 0.62, 0.23, 0.98],
          }}
          onClick={(event) => event.stopPropagation()}
          className={clsx(classes.nestedLinks)}
        >
          {filteredNestedLinks.map((navItem, index) => {
            const {
              link: nestedLink,
              name: nestedLinkName,
              disabledCond,
              tooltipFn,
              isHidden,
              nestedLinks,
              isk8sNav,
            } = navItem
            const name = processName(nestedLinkName)
            const disableK8sNavs = isk8sNav && !k8sClusterAvailable

            if (nestedLinks) {
              return (
                <NavSection
                  {...navItem}
                  key={`${name}-${index}`}
                  isActive={matchesCurrentPath(currentPath, navItem)}
                  drawerOpen={drawerOpen}
                  nestingLevel={2}
                  disabled={disableK8sNavs}
                  info={info ? info : disableK8sNavs ? 'No Clusters Available' : ''}
                />
              )
            }

            return isHidden && isHidden({ virtualNetworking }) ? null : (
              <NavItem
                key={index}
                name={name}
                link={nestedLink}
                activeDisplayType="bar"
                className={classes.nestedNavItem}
                isActive={matchesCurrentPath(currentPath, navItem)}
                drawerOpen={drawerOpen}
                tooltip={tooltipFn ? tooltipFn({ virtualNetworking }) : '' || info}
                variant="sidenav2"
                tooltipProps={{ className: classes.nestNavContainer }}
                disabled={disabledCond ? disabledCond({ virtualNetworking }) : false || disabled}
              />
            )
          })}
        </motion.div>
      )}
    </>
  )
}
interface StyleProps {
  isActive: boolean
  open: boolean
  drawerOpen: boolean
  innerNested: boolean
  isAccountPlugin?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  navHeader: {
    display: 'flex',
    flexDirection: 'column',

    '&:hover': {
      transition: 'background 0.2s ease',
      background: ({ isActive }) => (isActive ? 'unset' : theme.components.sidebar.border),
    },
  },
  navIcon: {
    display: ({ drawerOpen }) => (drawerOpen ? 'flex' : 'none'),
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 24,
    position: 'relative',
    left: ({ innerNested, isAccountPlugin }) => (innerNested ? 200 : isAccountPlugin ? 178 : 240),
    bottom: 25,
    color: ({ isActive }) => theme.components.sidebar?.[isActive ? 'activeText' : 'text'],
    transform: ({ open }) => (open ? 'rotate(-180deg)' : 'rotate(0deg)'),
    transition: 'transform 0.6s ease, color 0.2s ease',
    '&:hover': {
      color: theme.components.sidebar.hoverText,
    },
  },
  navItemContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginLeft: 23,
    color: ({ isActive }) => theme.components.sidebar?.[isActive ? 'activeText' : 'text'],
  },
  nestNavContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    color: ({ isActive }) => theme.components.sidebar?.[isActive ? 'activeText' : 'text'],
    paddingLeft: theme.spacing(2),
  },
  nestedNavItem: {
    '&:hover': {
      transition: 'background 0.2s ease',
      background: theme.components.sidebar.border,
    },
  },
  nestedLinks: {
    overflow: 'hidden',
    marginLeft: theme.spacing(4.5),
  },
}))
