import React from 'react'
import Checkbox from 'core/elements/input/Checkbox'
import Radio from 'core/elements/input/Radio'
import { noop } from 'utils/fp'
import { memoizeShallow } from 'utils/misc'
import generateTestId from 'utils/test-helpers'
import { SelectableParsedGridRow } from './hooks/useGridSelectableRows'
import { GridRowMenuItemsProps, GridRowMenuOffset } from './hooks/useGridRowMenu'
import GridRowMenu from './GridRowMenu'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

interface SelectRowColumnProps {
  className: string
  multiSelection: boolean
  isSelectable: boolean
  isSelected: boolean
}

const SelectRowColumn = memoizeShallow(
  function SelectRowColumn({
    className,
    multiSelection,
    isSelectable,
    isSelected,
  }: SelectRowColumnProps) {
    if (isSelectable === undefined) {
      return null
    }
    const Toggler = multiSelection ? Checkbox : Radio
    return (
      <td data-testid={generateTestId('cluster', 'checkbox', 'selection')} className={className}>
        <Toggler disabled={!isSelectable} checked={isSelected} onChange={noop} />
      </td>
    )
  },
  {
    maxSize: 8,
  },
)

export interface GridRowProps<T> extends SelectableParsedGridRow<T>, GridRowMenuItemsProps<T> {
  className: string
  tdClassName: string
  cellClassName: string
  index?: number
  numPageItems?: number
  rowMenuOffset?: GridRowMenuOffset
  hideCheckboxes?: boolean
  isReadOnly?: boolean
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    position: 'relative',
  },
  stickied: {
    position: 'absolute',
    right: 0,
    zIndex: 10,
  },
}))

export default function GridRow<T>(props: GridRowProps<T>) {
  const {
    isSelectable,
    multiSelection,
    isSelected,
    toggleSelect,
    getCells,
    rowMenuItems = [],
    rowMenuDisabled = !rowMenuItems.length,
    item,
    className,
    tdClassName,
    cellClassName,
    index,
    numPageItems,
    rowMenuOffset = {},
    showRowMenuForSingleRowActions,
    maxRowMenuHeight,
    hideCheckboxes,
    isReadOnly,
  } = props
  const classes = useStyles()
  return (
    <>
      {isReadOnly ? null : rowMenuItems.length ? (
        <div className={classes.stickied}>
          <GridRowMenu
            item={item}
            rowMenuItems={rowMenuItems}
            rowMenuDisabled={rowMenuDisabled}
            rowMenuOffset={
              index === numPageItems - 1
                ? {
                    vertical: rowMenuOffset.vertical * -1,
                  }
                : rowMenuOffset
            }
            showRowMenuForSingleRowActions={showRowMenuForSingleRowActions}
            maxRowMenuHeight={maxRowMenuHeight}
            toggleRow={toggleSelect}
          />
        </div>
      ) : null}
      <tr className={clsx(classes.container, className)} onClick={toggleSelect}>
        {!hideCheckboxes && (
          <SelectRowColumn
            className={tdClassName}
            {...{
              multiSelection,
              isSelectable,
              isSelected,
            }}
          />
        )}
        {getCells().map(({ key, CellComponent, value, getFormattedValue }, idx) => {
          const formattedValue = getFormattedValue()
          return (
            <td data-testid={generateTestId(key)} key={key} className={tdClassName}>
              <CellComponent
                index={idx}
                item={item}
                value={value}
                title={String(formattedValue)}
                className={cellClassName}
              >
                {formattedValue}
              </CellComponent>
            </td>
          )
        })}
        {isReadOnly ? null : rowMenuItems.length ? <td /> : null}
      </tr>
    </>
  )
}
