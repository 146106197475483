import { AppPlugins, CustomerTiers, KaapiFeatures, ssoRedirectUrl } from 'app/constants'
import { pathStrOr } from 'utils/fp'
import { sessionStoreKey } from 'core/session/sessionReducers'
import store from 'app/store'
import { compareVersions, isAdminRole } from 'k8s/util/helpers'
import { getDomainId, getRegionNameFromURL } from 'api-client/helpers'

// Default True
export const isKaapiEnabled = () => {
  const state = store.getState()
  const { features } = state[sessionStoreKey]
  return features?.kaapi?.enable_kaapi === false ? false : true
}

export const isKaapiTokenAvailable = () => {
  const state = store.getState()
  const { jwtToken } = state[sessionStoreKey]
  return !!jwtToken
}

export const isInfraRegion = () => {
  return getRegionNameFromURL() === 'Infra'
}

// This function is used to get the active PCD tenant name from the redux store
export const getActiveTenantName = () => {
  const state = store.getState()
  const { activeTenant } = state[sessionStoreKey]
  return activeTenant || ''
}

export const isDecco = (features) => pathStrOr(false, 'experimental.kplane', features)

export const isDeccoEnv = () => {
  const state = store.getState()
  const { features } = state[sessionStoreKey]
  return isDecco(features)
}

export const isProductionEnv = process.env.NODE_ENV === 'production'

export const showZendeskChatOption = (lastStack, customerTier) =>
  (lastStack === AppPlugins.Kubernetes || lastStack === AppPlugins.MyAccount) &&
  customerTier === CustomerTiers.Enterprise &&
  isProductionEnv

export const convertListOfObjectsToMap = (key: string, objects: any[]) => {
  const result = new Map()
  for (const obj of objects) {
    if (obj[key]) {
      result.set(obj[key], obj)
    }
  }
  return result
}

export const devEnabled = () => window.localStorage.enableDevPlugin === 'true'

const READONLY_ROLES = ['reader']
export const isReadonlyUser = () => {
  const state = store.getState()
  const { userDetails } = state[sessionStoreKey]
  return READONLY_ROLES.includes(userDetails?.role)
}

export const isDefaultDomain = (domain?: string) => {
  return (
    !domain ||
    domain.trim() === '' ||
    domain.trim() === 'Default Domain' ||
    domain?.trim()?.toLowerCase() === 'default'
  )
}

export const getSSORedirectUrl = (domain?: string) => {
  if (isDefaultDomain(domain)) {
    return ssoRedirectUrl
  } else {
    return ssoRedirectUrl.replace('IDP1', domain)
  }
}

export const formatNumberWithLabel = (value) => {
  if (value >= 1e12) {
    const valueInT = (value / 1e12).toFixed(0)
    return { value: valueInT, label: 'T' }
  } else if (value >= 1e9) {
    const valueInB = (value / 1e9).toFixed(0)
    return { value: valueInB, label: 'B' }
  } else if (value >= 1e6) {
    const valueInM = (value / 1e6).toFixed(0)
    return { value: valueInM, label: 'M' }
  } else if (value >= 1e3) {
    const valueInK = (value / 1e3).toFixed(0)
    return { value: valueInK, label: 'k' }
  } else if (Number.isInteger(value)) {
    return { value: value.toString(), label: '' }
  } else {
    return { value: value.toFixed(1), label: '' }
  }
}

export const getTimeRangeData = (selectedTimeRange: string, isModalView?: boolean) => {
  const currentDate = new Date()
  let endDate: Date
  let startDate: Date
  let steps: string
  let interval: number
  switch (selectedTimeRange) {
    case 'last_one_hour': {
      const now = new Date(currentDate)
      startDate = new Date(now)
      startDate.setHours(now.getHours() - 1) // Set startDate to one hour before current time
      endDate = new Date(now) // Set endDate to the current time
      steps = '5m'
      break
    }
    case 'last_3_hours': {
      const now = new Date(currentDate)
      startDate = new Date(now)
      startDate.setHours(now.getHours() - 6) // Set startDate to 6 hour before current time
      endDate = new Date(now) // Set endDate to the current time
      steps = isModalView ? '7m' : '15m'
      break
    }
    case 'last_6_hours': {
      const now = new Date(currentDate)
      startDate = new Date(now)
      startDate.setHours(now.getHours() - 6) // Set startDate to 6 hour before current time
      endDate = new Date(now) // Set endDate to the current time
      steps = isModalView ? '15m' : '30m'
      break
    }
    case 'last_12_hours': {
      const now = new Date(currentDate)
      startDate = new Date(now)
      startDate.setHours(now.getHours() - 12) // Set startDate to 12 hour before current time
      endDate = new Date(now) // Set endDate to the current time
      steps = isModalView ? '30m' : '1h'
      break
    }
    case 'last_24_hours': {
      const now = new Date(currentDate)
      startDate = new Date(now)
      startDate.setHours(now.getHours() - 24) // Set startDate to 24 hour before current time
      endDate = new Date(now) // Set endDate to the current time
      steps = isModalView ? '1h' : '2h'
      break
    }
    case 'today': {
      startDate = new Date(currentDate)
      startDate.setHours(0, 0, 0, 0) // Set the time to the start of the day (00:00:00.000)
      endDate = new Date(currentDate)
      steps = '4h'
      break
    }
    case 'yesterday': {
      const yesterday = new Date(currentDate)
      yesterday.setDate(yesterday.getDate() - 1)
      startDate = new Date(yesterday)
      startDate.setHours(0, 0, 0, 0) // Set to 12:00:00 AM (start of the day)
      endDate = new Date(yesterday)
      endDate.setHours(23, 59, 59, 999)
      steps = '4h'
      break
    }
    case 'last_week': {
      const currentDayOfWeek = currentDate.getDay()
      const startOfWeek = new Date(currentDate)
      startOfWeek.setDate(currentDate.getDate() - currentDayOfWeek - 6)
      startOfWeek.setHours(0, 0, 0, 0) // Calculate the end of the last week (Sunday at 11:59 PM)

      const endOfWeek = new Date(currentDate)
      endOfWeek.setDate(currentDate.getDate() - currentDayOfWeek)
      endOfWeek.setHours(23, 59, 59, 999)

      startDate = new Date(startOfWeek.getTime())
      endDate = new Date(endOfWeek.getTime())

      steps = '1d'
      break
    }
    case 'last_month': {
      const currentMonth = currentDate.getMonth()
      const currentYear = currentDate.getFullYear()
      const firstDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 1, 0, 0, 0, 0) // Calculate the last day of the previous month

      const lastDayOfPreviousMonth = new Date(currentYear, currentMonth, 0, 23, 59, 59, 999)

      startDate = new Date(firstDayOfPreviousMonth.getTime())
      endDate = new Date(lastDayOfPreviousMonth.getTime())
      steps = '1d'
      interval = 4
      break
    }
    case 'last_7_days': {
      startDate = new Date(currentDate)
      startDate.setDate(currentDate.getDate() - 6)
      endDate = new Date(currentDate)
      steps = '1d'
      break
    }
    case 'last_30_days': {
      startDate = new Date(currentDate)
      startDate.setDate(currentDate.getDate() - 29)
      endDate = new Date(currentDate)
      steps = '1d'
      interval = 4
      break
    }
    case 'last_60_days': {
      startDate = new Date(currentDate)
      startDate.setDate(currentDate.getDate() - 59)
      endDate = new Date(currentDate)
      steps = '1d'
      interval = 8
      break
    }
    default: {
      // Handle an unknown or default case
      startDate = new Date(currentDate) // Default to current date and time
      endDate = new Date(currentDate)
      steps = '4h'
      break
    }
  }

  return {
    startTimeEpoch: startDate?.getTime() / 1000,
    endTimeEpoch: endDate?.getTime() / 1000,
    steps,
    interval,
  }
}

export const isVersionGreaterOrEqual = (version1, version2) => {
  const [major1, minor1, patch1] = version1?.split('.')?.map(Number)
  const [major2, minor2, patch2] = version2?.split('.')?.map(Number)

  return (
    major1 > major2 ||
    (major1 === major2 && minor1 > minor2) ||
    (major1 === major2 && minor1 === minor2 && patch1 >= patch2)
  )
}

export const cleanVersionStr = (version = '') =>
  version.length >= 1 && version[0] === 'v' ? version.slice(1) : version
// Get the version that is 1 higher than the current version
// Ex. getNextVersion('1.24.3') would return 1.25
export const getNextVersion = (versionString): string => {
  if (!versionString) return null
  const fullVersion = versionString?.split('-')[0]
  const version = parseFloat(fullVersion)
  return (version + 0.01).toFixed(2)
}

export const getRequiredVersion = (
  currentVersion: string,
  options: { label: string; value: string }[],
): string | null => {
  const [currentMajor, currentMinor] = currentVersion.split('.').map(Number)

  // Extract and sort unique major.minor groups
  const majorMinorGroups = Array.from(
    new Set(
      options.map(({ value }) =>
        value
          .split('.')
          .slice(0, 2)
          .join('.'),
      ),
    ),
  ).sort((a, b) => {
    const [aMajor, aMinor] = a.split('.').map(Number)
    const [bMajor, bMinor] = b.split('.').map(Number)

    if (aMajor !== bMajor) return aMajor - bMajor
    return aMinor - bMinor
  })

  // Find the next major.minor group after the current one
  const nextMajorMinor = majorMinorGroups.find((group) => {
    const [groupMajor, groupMinor] = group.split('.').map(Number)
    return groupMajor > currentMajor || (groupMajor === currentMajor && groupMinor > currentMinor)
  })

  if (!nextMajorMinor) return null // No next group found

  // Filter options for the next group and find the highest version
  const nextGroupVersions = options.filter(({ value }) => {
    const [major, minor] = value.split('.').map(Number)
    const [nextMajor, nextMinor] = nextMajorMinor.split('.').map(Number)
    return major === nextMajor && minor === nextMinor
  })

  const highestVersion = nextGroupVersions.sort((a, b) => {
    const [aMajor, aMinor, aPatch] = a.value.split('.').map(Number)
    const [bMajor, bMinor, bPatch] = b.value.split('.').map(Number)

    if (aMajor !== bMajor) return bMajor - aMajor
    if (aMinor !== bMinor) return bMinor - aMinor
    return bPatch - aPatch
  })[0]?.value

  return highestVersion || null
}
export const isLessThanOrEqualToTargetVer = (targetVersion: string, version: string): boolean => {
  const fullVersion = cleanVersionStr(version)?.split('-')[0]
  const versions = fullVersion?.split('.')
  const majorVersion = versions[0]
  const minorVersion = versions[1]
  return `${majorVersion}.${minorVersion}` <= targetVersion
}
// export const getNextUpgradeVersion = (currVersion: string, allVersions: string[]): string => {
//   const targetVersion = getNextVersion(currVersion)
//   return allVersions?.reduce((acc, version) => {
//     if (
//       compareVersions(acc, version) === -1 &&
//       isLessThanOrEqualToTargetVer(targetVersion, version)
//     ) {
//       return version
//     }
//     return acc
//   }, currVersion)
// }

export const getNextUpgradeVersion = (currVersion: string, allVersions: string[]): string => {
  // Sort versions in ascending order so that we can find the next upgrade easily
  const sortedVersions = allVersions.sort((a, b) => compareVersions(a, b))

  // Find the next version that is greater than the current version
  const nextVersionIndex = sortedVersions.findIndex(
    (version) => compareVersions(currVersion, version) === -1,
  )

  // If the next version is found and it is less than or equal to the target version
  if (nextVersionIndex !== -1) {
    return sortedVersions[nextVersionIndex]
  }

  // If no next version found, return the current version
  return currVersion
}

export const trimInputOnBlur = (event, onChange) => {
  event.target.value = event?.target?.value?.trim()
  onChange(event.target.value)
}

// Admin user from default domain is system admin
export const isSystemAdminUser = () => {
  const state = store.getState()
  const session = state[sessionStoreKey]
  return isAdminRole(session) && isDefaultDomain(getDomainId())
}

export const isDevelopment = () => process.env.NODE_ENV === 'development'

export const isLocalhost = () => window.location.host.includes('localhost')
