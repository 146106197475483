import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Text from 'core/elements/Text'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(1),
  },
  valueText: {
    marginRight: theme.spacing(2),
  },
}))
interface StatusDetailProps {
  statusDetails: any
  className?: string
}
const DetailRow = ({ label, value }) => {
  const classes = useStyles()

  return (
    <div className={classes.row}>
      <Text className={classes.labelText} variant="body2">
        {label}
        {label && ':'}
      </Text>
      <Text className={classes.valueText} variant="caption1">
        {value}
      </Text>
    </div>
  )
}

const StatusDetails: React.FC<StatusDetailProps> = ({ statusDetails, className }) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.row, 'status-details', className)}>
      {statusDetails?.subtitles?.map((subtitle) => (
        <DetailRow key={subtitle.label} label={subtitle.label} value={subtitle.value} />
      ))}
    </div>
  )
}

export default StatusDetails
