import React, { useMemo } from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import Theme from 'core/themes/model'
import SemiCircleGraph from 'core/components/graphs/SemiCircleGraph'
import Text from 'core/elements/Text'
import Card from 'core/elements/card'
import CardBody from 'core/elements/card/CardBody'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'

const defaultStats = { current: 0, max: 0, percent: 0 }

const getBackgroundColor = ({ theme, percent }) => {
  if (percent < 50) {
    return theme.palette.graphGreen[100]
  } else if (percent < 80) {
    return theme.palette.graphYellow[100]
  } else {
    return theme.palette.graphRed[100]
  }
}

const getBorderColor = ({ theme, percent }) => {
  if (percent < 50) {
    return theme.palette.graphGreen[500]
  } else if (percent < 80) {
    return theme.palette.graphYellow[500]
  } else {
    return theme.palette.graphRed[500]
  }
}

interface Props {
  title: string
  units: string
  stats: typeof defaultStats
  precision?: number
  usedText?: string
  icon?: string
  primary?: string
}

export default function UsageWidget({
  title,
  precision = 1,
  units = '',
  usedText = 'used',
  stats = defaultStats,
  icon,
  ...rest
}: Props) {
  const classes = useStyles()
  const theme: any = useTheme()
  const { current, max, percent } = stats

  const curStr = current.toFixed(precision)
  const maxStr = max.toFixed(precision)
  const availableStr = (max - current).toFixed(precision)
  const percentage = Math.round(percent)
  const data = useMemo(
    () => [
      {
        value: 100 - percentage,
        name: '',
        color: 'tray' as const,
      },
      {
        value: percentage,
        name: '',
        // color: 'fadedPrimary' as const,
        color: getBackgroundColor({ theme, percent: percentage }),
        strokeColor: getBorderColor({ theme, percent: percentage }),
      },
    ],
    [percentage, theme],
  )
  return (
    <div>
      <Card className={classes.card} withCustomBody>
        <CardBody className={classes.cardBody}>
          <div className={classes.header}>
            <div className={classes.iconContainer}>
              <FontAwesomeIcon className={classes.icon} solid>
                {icon}
              </FontAwesomeIcon>
            </div>
            {units ? (
              <Text variant="subtitle2">
                {title} <span className={classes.units}>({units})</span>
              </Text>
            ) : (
              <Text variant="subtitle2">{title}</Text>
            )}
          </div>
          <SemiCircleGraph data={data} percent={percent / 100} {...rest} />
          <div className={classes.statLabels}>
            <div className={classes.leftStatLabel}>
              <Text variant="subtitle2" className={classes.smallLineHeight}>
                {curStr}
              </Text>
              <Text variant="body2">{usedText}</Text>
            </div>
            <div className={classes.rightStatLabel}>
              <Text variant="subtitle2" className={classes.smallLineHeight}>
                {availableStr}
              </Text>
              <Text variant="body2">available</Text>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    display: 'grid',
    gridTemplateRows: 'max-content max-content',
  },
  cardBody: {
    display: 'grid',
    justifyItems: 'center',
    paddingBottom: 32,
    '& path[name="unknown"]': {
      stroke: theme.components.graph.stroke,
      fill: theme.components.graph.tray,
    },
  },
  header: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    paddingBottom: 12,
  },
  iconContainer: {
    background: theme.palette.grey[100],
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    width: 32,
  },
  icon: {
    fontSize: '16px',
    color: theme.palette.grey[500],
  },
  statLabels: {
    position: 'relative',
    height: 24,
    top: -4,
  },
  smallLineHeight: {
    lineHeight: 1,
  },
  leftStatLabel: {
    position: 'absolute',
    left: -114,
    display: 'grid',
    justifyContent: 'center',
    textAlign: 'center',
  },
  rightStatLabel: {
    position: 'absolute',
    left: 68,
    display: 'grid',
    justifyContent: 'center',
    textAlign: 'center',
  },
  units: {
    color: theme.components.typography.passive,
    fontWeight: 'normal',
  },
}))
