import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { ClientState, clientStoreKey } from 'core/client/clientReducers'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { matchesCurrentPath } from 'core/plugins/route-helpers'
import Theme from 'core/themes/model'
import { partition, prop } from 'ramda'
import React, { FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import NavPane from './NavPane'
import NavSection from './NavSection'
import useListAction from 'core/hooks/useListAction'
import { listKaapiClusters } from 'k8s/components/kaapi/clusters/actions'
import { kaapiClustersSelector } from 'k8s/components/kaapi/clusters/selectors'
import { AppPlugins } from 'app/constants'

const OpenStackMainMenu: FC = () => {
  const { frame: { sidebarPane = 'default' } = {} } = useSelector(
    prop<string, ClientState>(clientStoreKey),
  )
  const { currentSection, currentPath } = usePluginRouter(AppPlugins.OpenStack)
  const classes = useStyles()
  const [_currentSectionBottomLinks, currentSectionLinks] = useMemo(
    () => partition((link) => link.isBottomLink, currentSection.links),
    [currentSection],
  )
  useListAction(listKaapiClusters)
  const clusterList = useSelector(kaapiClustersSelector)

  const renderNavItems = useCallback(() => {
    return currentSectionLinks.map((navItem, idx) => {
      return (
        <NavSection
          {...navItem}
          key={`${navItem?.name}-${idx}`}
          isActive={matchesCurrentPath(currentPath, navItem)}
          drawerOpen={false}
          menuOffset={{
            vertical: -48,
            horizontal: 8,
          }}
          k8sClusterAvailable={!!clusterList?.length}
        />
      )
    })
  }, [currentSectionLinks, currentPath, clusterList])

  return (
    <>
      <aside
        className={clsx(classes.customNav, classes.nav, {
          [classes.openPane]: sidebarPane === 'default',
          [classes.hiddenPane]: sidebarPane !== 'default',
        })}
      >
        <NavPane className={clsx(classes.primaryPane, 'thin-scrollbar')}>
          {renderNavItems()}
        </NavPane>
      </aside>
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  hiddenPane: {
    transform: `translate(-73px, 0px)`,
  },
  openPane: {
    transform: `translate(0px, 0px)`,
    backgroundColor: theme.components.sidebar.background,
  },
  customNav: {
    marginTop: 65,
  },
  nav: {
    position: 'relative',
    width: 72,
    zIndex: 100,
    height: 'calc(100vh - 65px)',
    borderRight: `1px solid ${theme.components.sidebar.border}`,
    backgroundColor: theme.components.sidebar.background,
    display: 'grid',
    justifyContent: 'stretch',
    transition: 'width .2s ease',
    gridTemplateRows: 'max-content 1fr',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `"nav-header" "nav-primary-pane"`,
  },
  primaryPane: {
    width: 72,
    zIndex: 90,
    gridArea: 'nav-primary-pane',
    maxHeight: '100vh',
    overflowY: 'visible',
    '& ul': {
      width: 72,
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
  },
}))

export default OpenStackMainMenu
