import { makeStyles } from '@material-ui/styles'
import { AppPlugins, CustomerTiers } from 'app/constants'
import { sessionActions, SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import { useToast } from 'core/providers/ToastProvider'
import { RootState } from 'app/store'
import { keys, pathOr, prop, paths } from 'ramda'
import pluginManager from 'core/plugins/pluginManager'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import DocumentMeta from 'core/components/DocumentMeta'
import Bugsnag from 'utils/bugsnag'
import { isInfraRegion } from 'core/utils/helpers'
import Watchdog from 'core/watchdog'
import sessionTimeoutCheck from 'core/watchdog/session-timeout'
import Progress from 'core/components/progress/Progress'
import DataKeys from 'k8s/DataKeys'
import { cacheStoreKey, loadingStoreKey } from 'core/caching/cacheReducers'
import { PreferencesState, preferencesStoreKey } from 'core/session/preferencesReducers'
import { determineCurrentStack, loadRegionFeatures } from 'core/containers/AuthController'
import useListAction from 'core/hooks/useListAction'
import { resmgrHostsSelector } from 'openstack/components/infrastructure/selectors'
import {
  hasHypervisorRole,
  hasImageLibraryRole,
  hasCinderRole,
  hostUnhealthy,
  getHeaderAlertMessage,
} from 'openstack/components/infrastructure/helpers'
import RedirectToSecondaryRegion from 'core/components/RegionDropdown'
import { regionsSelector } from 'app/plugins/infrastructure/components/regions/selectors'
import { listRegions } from 'app/plugins/infrastructure/components/regions/actions'
import BannerContainer from 'core/components/notifications/BannerContainer'
import BannerContent from 'core/components/notifications/BannerContent'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import {
  listResmgrHosts,
  getBlueprints,
  getHostNetworkConfigs,
} from 'openstack/components/infrastructure/actions'

interface StyleProps {
  path?: string
  showNavBar?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    backgroundColor: theme.palette.grey['000'],
  },
  secondaryHeader: {
    position: 'fixed',
    top: 55,
    width: '100%',
    zIndex: 1100,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    minHeight: theme.spacing(6),
  },
  contentMain: {
    padding: ({ showNavBar }) =>
      showNavBar ? theme.spacing(3, 3, 3, 3.5) : theme.spacing(3, 3, 3, 25),
  },
  sandboxBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,

    fontWeight: 'bold',
    fontSize: 16,
    '& a': {
      backgroundColor: '#F3F3F4',
      margin: theme.spacing(0, 1),
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
      '& *': {
        color: theme.palette.background,
      },
      '& i': {
        marginLeft: theme.spacing(),
      },
    },
  },
  modal: {
    position: 'fixed',
    left: 0,
    top: '55px',
    width: '100vw',
    height: 'calc(100vh - 55px)', // 55px is the toolbar height
    overflow: 'auto',
    zIndex: 1500,
    backgroundColor: theme.palette.grey['100'],
    padding: theme.spacing(2, 4),
    boxSizing: 'border-box',
  },
  headerAlert: {
    display: 'grid',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    gridTemplateColumns: 'max-content max-content',
    gap: 8,
  },
  headerIcon: {
    color: theme.palette.grey['000'],
  },
  headerAlertCancel: {
    color: theme.palette.grey['000'],
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
  },
}))

const allClustersLoadingPaths = paths<boolean>(
  [DataKeys.Clusters, DataKeys.ImportedClusters, DataKeys.CapiClusters].map((cacheKey) => [
    cacheStoreKey,
    loadingStoreKey,
    cacheKey,
  ]),
)

export const getUnderlayInfo = (blueprintSettings, hostConfigs) => {
  const underlayType = blueprintSettings?.virtualNetworking?.underlayType
  const vnidRange = blueprintSettings?.virtualNetworking?.vnidRange || ''
  const vniRanges = vnidRange
    .split(',')
    .map((range) => range.trim())
    .map((range) => {
      const parts = range.split(':')
      return parts[0] === parts[1] ? parts[1] : `${parts[0]}-${parts[1]}`
    })
  const firstHostConfig = hostConfigs?.[0]
  const tunnelPhysnet = firstHostConfig?.tunnelingInterface
  const labelKeys = keys(firstHostConfig?.networkLabels)
  const tunnelLabel = labelKeys?.find((key) => {
    return firstHostConfig?.networkLabels?.[key] === tunnelPhysnet
  })
  return {
    type: underlayType,
    label: tunnelLabel,
    ranges: vniRanges,
  }
}

const AuthContainer = () => {
  const { history, location } = useReactRouter()
  const { pathname } = history.location
  const dispatch = useDispatch()
  const showToast = useToast()
  const { prefs, updatePrefs } = useScopedPreferences()
  const { lastStack } = prefs
  // stack is the name of the plugin (ex. kubernetes, account, developer, theme)
  const [currentStack, setStack] = useState(determineCurrentStack(history.location, lastStack))
  const [showHeaderAlert, setShowHeaderAlert] = useState(false)
  const [headerAlertMessage, setHeaderAlertMessage] = useState('')
  const [headerAlertSet, setHeaderAlertSet] = useState(false)
  const [loadingFeatureFlags, setLoadingFeatureFlags] = useState(false)
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const {
    userDetails: { id: userId, name, displayName, role },
    features,
    onboardingRedirectToUrl,
    activeRegion,
    loadingRegion,
  } = session
  const airgapped = features?.experimental?.airgapped || false
  const { global = {} } = useSelector(prop<string, PreferencesState>(preferencesStoreKey))
  const { featureFlags: globalFeatureFlags } = global
  const customerTier = pathOr<CustomerTiers>(CustomerTiers.Freedom, ['customer_tier'], features)
  const plugins = pluginManager.getPlugins()
  const currentPlugin = plugins[currentStack]

  const PluginFrame = currentPlugin.getFrame()

  // Load regions list
  const { loading: loadingRegionList } = useListAction(listRegions, {
    initialLoadingState: true,
  })
  const regionList = useSelector(regionsSelector)
  // When only Infra region is available
  const isSingleRegionDeployment = useMemo(() => regionList?.length === 1, [regionList])

  const { loading: loadingResmgrHosts } = useListAction(listResmgrHosts, {
    initialLoadingState: true,
  })
  const resmgrHosts = useSelector(resmgrHostsSelector)
  useEffect(() => {
    if (!loadingResmgrHosts && !headerAlertSet) {
      const hypervisors = resmgrHosts.filter((host) => hasHypervisorRole(host))
      const imageLibraries = resmgrHosts.filter((host) => hasImageLibraryRole(host))
      const blockStorageNodes = resmgrHosts.filter((host) => hasCinderRole(host))
      const unhealthyHypervisors = hypervisors.filter((host) => hostUnhealthy(host))
      const unhealthyImageLibraries = imageLibraries.filter((host) => hostUnhealthy(host))
      const unhealthyBlockStorageNodes = blockStorageNodes.filter((host) => hostUnhealthy(host))
      const allHypervisorsUnhealthy = hypervisors.length === unhealthyHypervisors.length
      const allImageLibrariesUnhealthy = imageLibraries.length === unhealthyImageLibraries.length
      const allBlockStorageNodesUnhealthy =
        blockStorageNodes.length === unhealthyBlockStorageNodes.length
      const alertMessage = getHeaderAlertMessage({
        hypervisor: hypervisors.length && allHypervisorsUnhealthy,
        imageLibrary: imageLibraries.length && allImageLibrariesUnhealthy,
        blockStorage: blockStorageNodes.length && allBlockStorageNodesUnhealthy,
      })
      setHeaderAlertMessage(alertMessage)
      setShowHeaderAlert(!!alertMessage && true)
      setHeaderAlertSet(true)
    }
  }, [loadingResmgrHosts, resmgrHosts, headerAlertSet])

  const isLoadingInitialData = false
  // loadingClusters ||
  // (users?.length === 0 && loadingUsers) ||
  // (globalFeatureFlags === undefined && loadingFeatureFlags) ||
  // (isNilOrEmpty(features) && loadingRegion)

  const showOnboarding = false

  const shouldShowNavbarForCurrentStack =
    currentStack !== AppPlugins.MyAccount ||
    (currentStack === AppPlugins.MyAccount && role === 'admin')

  const showNavBar = shouldShowNavbarForCurrentStack && !loadingRegion
  // (!isOnboardingTargetEnv || (isOnboardingTargetEnv && globalFeatureFlags?.isOnboarded))

  const classes = useStyles({
    path: history.location.pathname,
    showNavBar: showNavBar,
  })

  // Check for openstack plugin and then check for the hosts

  // useEffect(() => {
  //   async function loadFeatureFlags() {
  //     if (isOnboardingTargetEnv) {
  //       setLoadingFeatureFlags(true)
  //       await updateOnboardingStatus()
  //       setLoadingFeatureFlags(false)
  //     }
  //   }

  //   loadFeatureFlags()
  // }, [isOnboardingTargetEnv])

  // useEffect(() => {
  //   async function loadClusters() {
  //     if (activeRegion) {
  //       await Promise.all([
  //         listClusters.call(),
  //         isDecco(features) ? listImportedClusters.call() : Promise.resolve(),
  //         listCapiClusters.call(),
  //       ])
  //     }
  //   }

  //   loadClusters()
  // }, [activeRegion, features])

  useEffect(() => {
    async function loadBlueprintSettings() {
      if (activeRegion) {
        const blueprints = await getBlueprints()
        const blueprintSettings = blueprints?.[0]
        if (!blueprintSettings) {
          return
        }
        const virtualNetworking = !!blueprintSettings?.virtualNetworking?.enabled
        const hostConfigs = await getHostNetworkConfigs()
        const underlayNetwork = getUnderlayInfo(blueprintSettings, hostConfigs)
        dispatch(
          sessionActions.updateSession({
            virtualNetworking,
            underlayNetwork,
          }),
        )
      }
    }
    loadBlueprintSettings()
  }, [activeRegion, features])

  useEffect(() => {
    if (showOnboarding) return
    // if (shouldBackfillOnboarding) {
    //   updateGlobalFeatureFlags({ isOnboarded: true }, globalFeatureFlags)
    // }
    if (onboardingRedirectToUrl) {
      history.push(onboardingRedirectToUrl)
      dispatch(sessionActions.updateSession({ onboardingRedirectToUrl: null }))
    }
  }, [
    showOnboarding,
    // shouldBackfillOnboarding,
    globalFeatureFlags,
    onboardingRedirectToUrl,
    history,
  ])

  // Load features once on startup, other times will be handled by region selection
  useEffect(() => {
    const loadRegion = async () => {
      try {
        await loadRegionFeatures()
      } finally {
        dispatch(sessionActions.updateSession({ loadingRegion: false }))
      }
    }
    loadRegion()
  }, [])

  useEffect(() => {
    if (!location) {
      return
    }
    const newStack = determineCurrentStack(location, lastStack)
    setStack(newStack)
    updatePrefs({ lastStack: newStack })
  }, [location])

  useEffect(() => {
    Bugsnag.setUser(userId, name, displayName)

    const cleanupCb = Watchdog.register({
      handler: sessionTimeoutCheck(session, showToast),
      frequency: 1000,
    })
    // Reset the interval if the session changes
    return () => {
      Bugsnag.setUser()
      cleanupCb()
    }
  }, [])

  // If the user logs in directly to the Infra page, redirect them to the region URL
  const shouldRedirectToRegionURL = useMemo(() => {
    if (loadingRegion || loadingRegionList) {
      return false
    }
    return isInfraRegion() && !isSingleRegionDeployment
  }, [loadingRegion, isInfraRegion, isSingleRegionDeployment, loadingRegionList])

  // Add Zendesk widget script only for Enterprise users
  // useEffect(() => {
  //   if (!airgapped && customerTier === CustomerTiers.Enterprise && isProductionEnv) {
  //     addZendeskWidgetScriptToDomBody({ userId, displayName, email: name })
  //   }
  //   return () => {
  //     hideZendeskWidget()
  //   }
  // }, [userId, displayName, name, customerTier])

  // const renderOnboardingWizard = () => (
  //   <div id="onboarding" className={classes.modal}>
  //     <OnboardingPage initialStep={onboardingWizardStep} />
  //   </div>
  // )

  return (
    <>
      <DocumentMeta title="Welcome" />
      {loadingRegion || isLoadingInitialData || shouldRedirectToRegionURL ? (
        // (isOnboardingTargetEnv && globalFeatureFlags?.isOnboarded === undefined) ? (
        <>
          <Progress
            loading
            message={
              shouldRedirectToRegionURL ? 'Redirecting to region...' : 'Loading region data...'
            }
          >
            {/* Redirect to secondary region if needed */}
            {shouldRedirectToRegionURL && <RedirectToSecondaryRegion hideDropdown={true} />}
          </Progress>
        </>
      ) : (
        <>
          {showHeaderAlert && (
            <>
              <BannerContainer />
              <BannerContent>
                <div className={classes.headerAlert}>
                  <FontAwesomeIcon className={classes.headerIcon}>
                    exclamation-triangle
                  </FontAwesomeIcon>
                  {headerAlertMessage}
                  <FontAwesomeIcon
                    className={classes.headerAlertCancel}
                    onClick={() => setShowHeaderAlert(false)}
                  >
                    xmark
                  </FontAwesomeIcon>
                </div>
              </BannerContent>
            </>
          )}
          {/* eslint-disable-next-line no-constant-condition */}
          <PluginFrame currentPluginId={currentStack} features={features} role={role} />
        </>
      )}
    </>
  )
}

export default AuthContainer

/* John: commenting this out for now, the sandbox is not operational currently

{pathStrOr(false, 'experimental.sandbox', features) && (
  <>
    <BannerContainer />
    <BannerContent>
      <div className={classes.sandboxBanner}>
        Welcome! You are in the Platform9 live demo.{' '}
        <Button
          component="a"
          target="_blank"
          href={pmkftSignupLink}
          onClick={() =>
            trackEvent('Live Demo - Signup Request', { 'CTA-Page': 'PMK Live Demo' })
          }
        >
          Start your Free Plan Now
        </Button>{' '}
        or{' '}
        <Button
          component="a"
          target="_blank"
          href={getSandboxUrl('contact')}
          onClick={() => trackEvent('CTA Contact Us', { 'CTA-Page': 'PMK Live Demo' })}
        >
          Contact Us
        </Button>
      </div>
    </BannerContent>
  </>
)} */
