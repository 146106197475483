import { getRegionNameFromURL, setActiveRegion } from 'api-client/helpers'
import React, { useCallback, useMemo, useEffect } from 'react'
import { prop, isEmpty } from 'ramda'
import { appUrlRoot, loginWithParams, loginWithSsoUrl } from 'app/constants'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { sessionActions, SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import store, { RootState } from 'app/store'
import { useSelector } from 'react-redux'
import useListAction from 'core/hooks/useListAction'
import { listRegions } from 'app/plugins/infrastructure/components/regions/actions'
import { regionsSelector } from 'app/plugins/infrastructure/components/regions/selectors'
import withProgress from 'core/components/progress/withProgress'
import Dropdown from 'core/elements/dropdown'
import { projectAs } from 'utils/fp'
import { groupByRegion } from 'api-client/Keystone'
import ApiClient from 'api-client/ApiClient'

const AsyncDropdown = withProgress(Dropdown, { inline: true })
const isDevelopment = process.env.NODE_ENV === 'development'
const currentSectionRegex = new RegExp(`^${appUrlRoot}/[^/]+/?[^/]*`, 'i')

const RegionDropdown = ({ hideDropdown = false }) => {
  const { serviceCatalog } = ApiClient.getInstance()
  const { prefs, updatePrefs } = useScopedPreferences()
  const { currentTenant, currentRegion } = prefs

  // Load regions
  const { loading: loadingRegions, reload: reloadRegions } = useListAction(listRegions, {
    initialLoadingState: true,
  })
  const regionsData = useSelector(regionsSelector)
  const regions = useMemo(() => {
    if (regionsData?.length === 1) {
      return regionsData
    }
    return regionsData.filter((region) => region.id !== 'Infra')
  }, [regionsData])
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { activeRegion } = session

  // When only Infra region is available
  const isSingleRegionDeployment = useMemo(() => regionsData?.length === 1, [regionsData])

  // Update isSingleRegionDeployment in session
  useEffect(() => {
    if (isSingleRegionDeployment) {
      store.dispatch(
        sessionActions.updateSession({
          isSingleRegionDeployment: true,
        }),
      )
    }
  }, [isSingleRegionDeployment])

  // const currentRegionId = useMemo(() => {
  //   if (currentRegion && find(propEq('id', currentRegion), regions)) {
  //     return currentRegion
  //   }
  //   return pipe(head, prop('id'))(regions)
  // }, [regions, currentRegion])

  // Region Options
  const options = useMemo(() => projectAs({ label: 'id', value: 'id' }, regions), [regions])

  useEffect(() => {
    // Reload region when changing the current tenant
    if (isEmpty(regions) && !loadingRegions) {
      reloadRegions(true)
    }
  }, [currentTenant, regions])

  const handleRegionSelect = useCallback(
    async (regionId) => {
      if (regionId === currentRegion && regionId === activeRegion) {
        return
      }
      updateRegion(regionId)
    },
    [currentRegion, activeRegion],
  )

  const redirectToRegionLogin = (regionURL) => {
    const { tokenData, username, unscopedToken, isSsoToken, domain } = session
    const regionEndpoint = new URL(regionURL).origin

    // If the region is the same as the current origin, no need to redirect
    if (regionEndpoint === window.location.origin && !isDevelopment) {
      return
    }

    // Create a redirect URL with the region endpoint and the current token data
    const url = new URL(
      `${regionEndpoint}${isSsoToken ? loginWithSsoUrl : loginWithParams}?issuedAt=${
        tokenData?.issuedAt
      }&username=${username}&unscopedToken=${unscopedToken}&expiresAt=${
        tokenData?.expiresAt
      }&ssoLogin=${isSsoToken}&domain=${domain}`,
    )

    window.location.replace(url)
  }

  // Get region endpoints
  const regionEndpoints = useMemo(() => {
    const servicesByRegion = groupByRegion(serviceCatalog)
    const regionKeys = Object.keys(servicesByRegion)
    return regionKeys.reduce((accum, region) => {
      const resmgrEndpoint = servicesByRegion?.[region]?.resmgr?.public?.url
      const baseUrl = resmgrEndpoint?.split('/resmgr')?.[0]
      return {
        ...accum,
        [region]: baseUrl,
      }
    }, {})
  }, [serviceCatalog])

  // Calculate the current region based on the service catalog and the current origin
  const calcCurrentRegion = useMemo(() => getRegionNameFromURL(), [
    window.location.host,
    serviceCatalog,
  ])

  useEffect(() => {
    // Handle for localhost
    if (isDevelopment || window.location.host.includes('localhost') || isSingleRegionDeployment)
      return

    // If the region is already set, no need to redirect
    if (
      calcCurrentRegion !== 'Infra' &&
      calcCurrentRegion === currentRegion &&
      calcCurrentRegion === activeRegion
    ) {
      return
    }

    const setupRegion = async () => {
      // Get the first region from the list and update as the active region
      if (calcCurrentRegion === 'Infra') {
        const firstRegion = options[0]?.value
        if (firstRegion) {
          updateRegion(firstRegion)
        }
      } else {
        // In this case no redirect is needed, just update the active region
        const newRegion = calcCurrentRegion ? calcCurrentRegion : currentRegion
        updatePrefs({ currentRegion: newRegion })
        await setActiveRegion(newRegion)
      }
    }

    setupRegion()
  }, [activeRegion, options, currentRegion, calcCurrentRegion, isSingleRegionDeployment])

  const updateRegion = useCallback(
    async (regionId) => {
      try {
        updatePrefs({ currentRegion: regionId })
        const redirectUrl = isDevelopment ? window.location.origin : regionEndpoints[regionId]
        redirectToRegionLogin(redirectUrl)
        await setActiveRegion(regionId)
      } catch (err) {
        console.error(err)
      }
    },
    [isDevelopment, regionEndpoints],
  )

  // Hide the dropdown if only redirection logic is needed
  if (hideDropdown) {
    return null
  }

  return (
    <AsyncDropdown
      name="region"
      label="Region"
      compact={true}
      value={activeRegion}
      onChange={(region) => handleRegionSelect(region)}
      loading={loadingRegions}
      items={options}
    />
  )
}

export default RegionDropdown
