import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import StatusDetails from 'core/components/status-bar/StatusDetails'
import Button from 'core/elements/button/Button'
import Text from 'core/elements/Text'
import colors from 'core/themes/base/colors'
import Theme from 'core/themes/model'
import { hexToRgbaCss } from 'core/utils/colorHelpers'
import {
  ClusterUpgradeStatus,
  clusterUpgradeStatusMap,
} from 'k8s/components/kaapi/cluster-upgrade/model'
import ClusterStatusSpan from 'k8s/components/resources/persistent-volume-claims/ClusterStatusSpan'
import { IClusterStatus } from 'k8s/components/resources/persistent-volume-claims/model'
import StepHorizontalBar from './StepHorizontalBar'
import { isNumeric } from 'pf9-ui-components/built/utils/misc'
import clsx from 'clsx'

interface StyleProps {
  status: IClusterStatus
}

export enum Status {
  InProgress = 'inProgress',
  Success = 'success',
  Failed = 'failed',
  NotStarted = 'notStarted',
  Warning = 'warning',
}

const ErrorMessage = ({ text }) => {
  return (
    <Text variant="caption1">
      Error:{' '}
      <Text component="span" variant="body2">
        {text}
      </Text>
    </Text>
  )
}
interface Error {
  reason: string
  message: string
}
interface StatusDetails {
  status?: IClusterStatus
  step?: number
  title: string
  description?: string
  error?: Error
  subtitles: { label: string | ReactNode; value: string | ReactNode }[]
}
interface StatusBarPorps {
  statusDetails: StatusDetails
  stepDetails?: any
  onSeeDetailsClick?: () => void
  onRetryClick?: () => void
  onClose?: () => void
}

export const getClusterUpgradeTitle = (status) => {
  switch (status) {
    case ClusterUpgradeStatus.Completed:
      return 'Kubernetes version upgrade completed'
    case ClusterUpgradeStatus.Failed:
      return 'Kubernetes version upgrade failed'
    case ClusterUpgradeStatus.InProgress:
      return 'Kubernetes version upgrade in progress...'
    default:
      return 'Kubernetes version upgrade in progress...'
  }
}

const StatusBar = ({
  statusDetails,
  onSeeDetailsClick,
  onClose,
  onRetryClick,
  stepDetails,
}: StatusBarPorps) => {
  const { status, title, error, step, description } = statusDetails
  const classes = useStyles({ status: status })
  return (
    <div className={classes.statusBar}>
      <div className={classes.statusBarContainer} data-testid="emp-creation-progress-bar">
        {status && (
          <div className={description ? classes.icon : clsx(classes.icon, classes.customIcon)}>
            <ClusterStatusSpan iconStatus showCircleIcon status={status} />
          </div>
        )}
        {isNumeric(step) && (
          <div>
            <Text component="figure" variant="caption1" className={classes.figureStep}>
              {step}
            </Text>
          </div>
        )}

        <div>
          <Text className={classes.stepTitle} variant="subtitle2">
            {title}
          </Text>
          {description && (
            <Text className={classes.labelText} variant="body2">
              {description}
            </Text>
          )}

          <div
            className={
              description
                ? classes.statusDetails
                : clsx(classes.statusDetails, classes.labelStatusDetails)
            }
          >
            {status === clusterUpgradeStatusMap.Failed ? (
              <ErrorMessage text={error?.reason} />
            ) : (
              <StatusDetails statusDetails={statusDetails} />
            )}
          </div>
        </div>
        <div className={classes.customButton}>
          {onSeeDetailsClick && (
            <Button variant="secondary" onClick={onSeeDetailsClick}>
              See Details
            </Button>
          )}

          {status === clusterUpgradeStatusMap.Failed && (
            <Button
              className={classes.retryButton}
              icon="refresh"
              variant="secondary"
              onClick={onRetryClick}
            >
              Retry Upgrade
            </Button>
          )}
        </div>

        {status === clusterUpgradeStatusMap.Completed && (
          <div className={classes.closeIcon}>
            <FontAwesomeIcon solid onClick={onClose}>
              xmark
            </FontAwesomeIcon>
          </div>
        )}
      </div>
      {stepDetails && <StepHorizontalBar steps={stepDetails} />}
    </div>
  )
}

export default StatusBar

const getBackgroundColor = (status: IClusterStatus, theme: Theme) =>
  ({
    ok: hexToRgbaCss(colors.teal[100], 0.05),
    pause: hexToRgbaCss(theme.palette.yellow.main, 0.05),
    fail: hexToRgbaCss(theme.palette.pink[800], 0.05),
    error: hexToRgbaCss(theme.palette.pink[800], 0.05),
    loading: hexToRgbaCss(theme.palette.blue[700], 0.05),
  }[status] || hexToRgbaCss(theme.palette.blue[700], 0.05))

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  statusBar: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    background: ({ status }) => getBackgroundColor(status, theme),
    marginBottom: `${theme.spacing(2)}px`,
    borderRadius: theme.spacing(0.5),
    position: 'relative',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  statusBarContainer: {
    display: 'flex',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(
      2,
    )}px`,
  },
  statusDetails: {
    display: 'flex',
  },
  icon: {
    '& i': {
      fontSize: theme.spacing(3),
    },
    marginTop: theme.spacing(1.5),
  },
  customButton: {
    alignSelf: 'center',
    '& .button-text, & .button-icon': {
      color: theme.palette.grey[600],
    },
    marginLeft: 'auto',
  },
  closeIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    '& i': {
      width: '100%',
      height: '100%',
    },
    '& i::before': {
      display: 'block',
      width: '100%',
      height: '100%',
    },
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(0.5),
  },
  stepTitle: {
    marginTop: '0.5px',
    marginLeft: '0.5px',
  },
  retryButton: {
    marginLeft: theme.spacing(2),
  },
  figureStep: {
    backgroundColor: theme.components.wizard.multiStep.bubbleBackground,
    border: `1px solid ${theme.components.wizard.multiStep.bubbleBorder}`,
    boxSizing: 'border-box',
    borderRadius: '100%',
    width: 24,
    height: 24,
    display: 'inline-grid',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    marginRight: 12,
    paddingBottom: 1,
  },
  labelText: {
    color: theme.palette.grey[600],
    paddingTop: theme.spacing(0.5),
  },
  labelStatusDetails: {
    paddingTop: theme.spacing(1),
  },
  customIcon: {
    marginRight: theme.spacing(1.5),
  },
}))
