import {
  Dispatch,
  Reducer,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { isEmpty, pick, zipObj, equals } from 'ramda'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { memoize } from 'utils/misc'

type ValueOf<T> = T[keyof T]

interface ParamsReducerAction<T> {
  type: 'merge' | 'replace'
  payload: Partial<T>
}

type ParamsReducer<T> = Reducer<T, ParamsReducerAction<T>>

const paramsReducer: <T>(state: T, { type, payload }: ParamsReducerAction<T>) => T = <T>(
  state: T,
  { type, payload },
) => {
  switch (type) {
    case 'merge':
      if (!equals(state, payload)) {
        return { ...state, ...payload }
      }
      return state
    case 'replace':
    default:
      return payload
  }
}

type SingleParamUpdaterGetter<P> = <K extends keyof P>(
  key: K,
) => (value: P[K]) => Promise<void> | void

type VariadicParamsUpdaterGetter<P> = <K extends keyof P>(
  ...keys: K[]
) => (...values: Array<P[K]>) => Promise<void> | void

type ParamsUpdaterGetter<P> = SingleParamUpdaterGetter<P> | VariadicParamsUpdaterGetter<P>

export interface UseParamsReturnType<P> {
  params: P
  updateParams: (newParams: Partial<P>) => void
  setParams: (newParams: P) => void
  getParamsUpdater: ParamsUpdaterGetter<P>
  paramsUpdated: boolean
  setParamsUpdated: Dispatch<SetStateAction<boolean>>
}

/**
 * Utility hook to handle a params object
 * Meant to be used along with useDataLoader/useDataUpdater hooks
 * @example
 *
 *   const { params, updateParams, getParamsUpdater } = useParams(defaultParams)
 *   const [data, loading, reload] = useDataLoader(cacheKey, params)
 *
 *   return <Picklist onChange={getParamsUpdater('clusterId')}
 *   Equivalent to:
 *   return <Picklist onChange={clusterId => updateParams({ clusterId })}
 */
const useParams = <P extends Record<string, unknown>>(defaultParams: P): UseParamsReturnType<P> => {
  // Added this to know when params are updated from their initial states
  const [paramsUpdated, setParamsUpdated] = useState<boolean>(false)
  const initialRenderRef = useRef(true)
  //@ts-ignore
  const [params, dispatch] = useReducer<ParamsReducer<P>>(paramsReducer, defaultParams)
  const getParamsUpdater = useMemo(() => {
    return memoize((...keys: Array<Extract<keyof P, string>>) => (...values: Array<ValueOf<P>>) => {
      setParamsUpdated(true)
      dispatch({
        // @fixme: zipObj return types are too loose so we are forced to use a type cast here
        type: 'merge',
        payload: zipObj<ValueOf<P>, string>(keys, values) as Partial<P>,
      })
    })
  }, []) as ParamsUpdaterGetter<P>

  const updateParams = useCallback((value: Partial<P>): void => {
    setParamsUpdated(true)
    dispatch({
      type: 'merge',
      payload: value,
    })
  }, [])

  const setParams = useCallback((value: P) => {
    setParamsUpdated(true)
    dispatch({
      type: 'replace',
      payload: value,
    })
  }, [])

  const { prefs } = useScopedPreferences()
  const { currentTenant, currentRegion } = prefs
  // Reset the params when the tenant or the region are changed
  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false
      return
    }
    setParamsUpdated(false)
    dispatch({ type: 'replace', payload: defaultParams || {} })
  }, [currentTenant, currentRegion])

  return {
    params: (params as unknown) as P,
    updateParams,
    setParams,
    getParamsUpdater,
    paramsUpdated,
    setParamsUpdated,
  }
}

/**
 * Creates a hook that combines useParams and usePrefs to persist specified param
 * keys to the user preferences store
 * @param storeKey User preferences store key
 * @param userPrefKeys Param keys that will be persisted into the user preferences
 */
export const createUsePrefParamsHook = <P extends Record<string, unknown>>(
  storeKey: string,
  userPrefKeys: Array<keyof P>,
) => {
  return (defaultParams?: P): UseParamsReturnType<P> => {
    const defaultPrefs = pick(userPrefKeys, defaultParams) as P
    const { prefs, updatePrefs } = useScopedPreferences<P>(storeKey, defaultPrefs)
    const {
      params,
      setParams: setParamsBase,
      updateParams: updateParamsBase,
      paramsUpdated,
      setParamsUpdated,
    } = useParams<P>(
      defaultParams
        ? {
            ...defaultParams,
            ...prefs,
          }
        : null,
    )

    const updateParams = useCallback(
      async (newParams: P) => {
        const newPrefs = pick(userPrefKeys, newParams) as P
        if (!isEmpty(newPrefs)) {
          await updatePrefs(newPrefs)
        }
      },
      [params],
    )

    const setParams = useCallback(
      async (newParams) => {
        const newPrefs = pick(userPrefKeys, newParams) as P
        if (!isEmpty(newPrefs)) {
          await updatePrefs(newPrefs)
        }
      },
      [params],
    )

    const getParamsUpdater = useMemo(() => {
      // eslint-disable-next-line @typescript-eslint/require-await
      return memoize((...keys: Array<Extract<keyof P, string>>) =>
        // @fixme: zipObj return types are too loose so we are forced to use a type cast here
        // eslint-disable-next-line @typescript-eslint/promise-function-async
        (...values: Array<ValueOf<P>>) =>
          updateParams(zipObj<ValueOf<P>, string>(keys, values) as P),
      )
    }, [updateParamsBase]) as ParamsUpdaterGetter<P>

    return {
      params,
      updateParams,
      setParams,
      getParamsUpdater,
      paramsUpdated,
      setParamsUpdated,
    }
  }
}

export default useParams
