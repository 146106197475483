import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'

export enum ViewMode {
  LIST = 'list',
  CARD = 'card',
}

interface ViewModeToggleProps {
  view: ViewMode
  onViewChange: (view: ViewMode) => void
}

const ViewModeToggle: React.FC<ViewModeToggleProps> = ({ view, onViewChange }) => {
  const classes = useStyles({ view })
  const url = new URL(window.location.href)

  const handleViewChange = (newView: ViewMode) => {
    // Update URL query parameter
    url.searchParams.set('view', newView)
    window.history.replaceState({}, '', url.toString())

    // Update the view state
    onViewChange(newView)
  }

  return (
    <div className={classes.viewActionsButtons}>
      <div
        className={clsx(classes.icon, classes.listIcon)}
        onClick={() => handleViewChange(ViewMode.LIST)}
        role="button"
        tabIndex={0}
        aria-label="Switch to list view"
      >
        <img src="/ui/images/list-icon.svg" alt="List view" />
      </div>
      <div
        className={clsx(classes.icon, classes.gridIcon)}
        onClick={() => handleViewChange(ViewMode.CARD)}
        role="button"
        tabIndex={0}
        aria-label="Switch to grid view"
      >
        <img src="/ui/images/grid-icon.svg" alt="Grid view" />
      </div>
    </div>
  )
}

interface StyleProps {
  view: ViewMode
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  viewActionsButtons: {
    width: theme.spacing(10),
    display: 'flex',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 4,
  },
  icon: {
    width: 40,
    height: 36,
    paddingTop: '2px',
    paddingBottom: '2px',
    gap: theme.spacing(1),
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  gridIcon: {
    opacity: ({ view }) => (view === ViewMode.LIST ? 0.5 : 1),
    borderLeft: ({ view }) => (view === ViewMode.CARD ? `1px solid ${theme.palette.grey[200]}` : 0),
    background: ({ view }) => (view === ViewMode.CARD ? theme.palette.common.white : 'unset'),
  },
  listIcon: {
    opacity: ({ view }) => (view === ViewMode.CARD ? 0.5 : 1),
    borderRight: ({ view }) =>
      view === ViewMode.LIST ? `1px solid ${theme.palette.grey[200]}` : 0,
    background: ({ view }) => (view === ViewMode.LIST ? theme.palette.common.white : 'unset'),
  },
}))

export default ViewModeToggle
