import { useContext, useEffect } from 'react'
import { allKey } from 'app/constants'
import { either, equals, isNil, reject, isEmpty } from 'ramda'
import { generateObjMemoizer } from 'utils/misc'
import { WizardSummaryFieldsProps } from './model'
import { WizardContext } from './Wizard'

const objMemoizer = generateObjMemoizer()

interface Props<T> extends WizardSummaryFieldsProps<T> {
  stepId?: string
}

export default function useWizardSummaryUpdater<T>({
  stepId = undefined,
  fields,
  calloutFields,
  keyOverrides,
  title,
  icon,
  ...rest
}: Props<T>) {
  // @ts-ignore
  const { setWizardSummaryProps, activeStepId } = useContext(WizardContext as any)
  const memoizedFields = objMemoizer(fields)
  const memoizedCalloutFields = objMemoizer(calloutFields)
  const memoizedKeyOverrides = objMemoizer(keyOverrides)
  useEffect(() => {
    if (stepId && activeStepId !== stepId) {
      return
    }
    const newPayload = reject(either(isNil, equals(allKey)))({
      ...rest,
      fields: memoizedFields,
      calloutFields: memoizedCalloutFields,
      keyOverrides: memoizedKeyOverrides,
      title,
      icon,
    })
    if (!isEmpty(newPayload)) {
      setWizardSummaryProps(newPayload)
    }
  }, [memoizedFields, memoizedCalloutFields, title, icon, memoizedKeyOverrides, activeStepId])
  return null
}
