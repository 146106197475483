import { withStyles } from '@material-ui/styles'
// import ClusterAddonManager, {
//   AddonDetailCards,
// } from 'app/plugins/infrastructure/components/clusters/cluster-addons/cluster-addon-manager'
import { assocPathStr, dissocPathStr, pathEqStr, pathStr, pathStrOr } from 'app/utils/fp'
import clsx from 'clsx'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { parseValidator } from 'core/utils/fieldValidators'
import PropTypes from 'prop-types'
import { assocPath, dissocPath, identity, pipe, toPairs } from 'ramda'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { memoize } from 'utils/misc'
import ValidatedFormDebug from './ValidatedFormDebug'

export const ValidatedFormContext = React.createContext({})

export const ValidatedFormConsumer = ValidatedFormContext.Consumer
export const ValidatedFormProvider = ValidatedFormContext.Provider

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
    marginBottom: theme.spacing(1),
    '& .MuiFormControl-root.validatedFormInput': {
      width: '100%',
      maxWidth: '400px',
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    '& .tooltip-container': {
      maxWidth: '400px',
    },
  },
  formActions: {
    display: 'flex',
    marginLeft: theme.spacing(2),
  },
})

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault()
  }
}

/**
 * ValidatedForm is a HOC wrapper for forms.  The child components define the
 * data value schema and the validations.
 */
@withRouter
@withStyles(styles, { withTheme: true })
class ValidatedForm extends PureComponent {
  constructor(props) {
    super(props)
    if (props.triggerSubmit) {
      props.triggerSubmit(this.handleSubmit)
    }
    if (props.fieldSetter) {
      props.fieldSetter(this.setFieldValue)
    }
  }

  /**
   * This stores the specification of the field, to be used for validation down the line.
   * This function will be called by the child components when they are initialized.
   */
  defineField = memoize((field) => (spec) => {
    this.setState(
      // Store the fields in a plain key-value map to be able to iterate over
      // them easily when performing the validations
      assocPath(['fields', field], spec),
      () => {
        if (this.state.showingErrors) {
          this.validateField(field)(null)
        }
      },
    )
  })

  removeField = (field) => {
    this.setState(pipe(dissocPath(['fields', field]), dissocPathStr(`errors.${field}`)))
  }

  setValues = (values) => {
    this.setState(
      (state) => {
        return { values: { ...state.values, ...values } }
      },
      () => {
        if (this.state.showingErrors || this.props.showErrorsOnBlur) {
          this.validateForm()
        }
      },
    )
  }

  /**
   * Child components invoke this from their 'onChange' (or equivalent).
   * Note: many components use event.target.value, but we only need value here.
   * Note: values can be passed up to parent component by supplying a setContext function prop
   */
  setFieldValue = memoize((field) => {
    const valuePath = `values.${field}`
    const hasErrPath = `errors.${field}.hasError`
    return (value, validateAll) => {
      this.setState(assocPathStr(valuePath, value), () => {
        if (
          this.state.showingErrors ||
          (this.props.showErrorsOnBlur && pathEqStr(hasErrPath, true, this.state))
        ) {
          if (validateAll) {
            this.validateForm()
          } else {
            this.validateField(field)(null)
          }
        }
      })
    }
  })

  /**
   * This can be used to update a field value using an updaterFn instead of assigning a value directly
   */
  updateFieldValue = memoize((field) => {
    const valuePath = `values.${field}`
    const hasErrPath = `errors.${field}.hasError`

    return (updaterFn, validateAll) => {
      this.setState(
        (state) => assocPathStr(valuePath, updaterFn(pathStr(valuePath, state)), state),
        () => {
          if (
            this.state.showingErrors ||
            (this.props.showErrorsOnBlur && pathEqStr(hasErrPath, true, this.state))
          ) {
            if (validateAll) {
              this.validateForm()
            } else {
              this.validateField(field)(null)
            }
          }
        },
      )
    }
  })

  getFieldValue = memoize((field) => (getterFn = identity) => {
    return getterFn(pathStr(`values.${field}`, this.state))
  })

  /**
   *  Validate the field and return false on error, true otherwise
   */
  validateField = memoize((fieldPath) => () => {
    const { fields, values } = this.state
    // Skip validation if the field has not been defined yet
    if (!fields.hasOwnProperty(fieldPath)) {
      return true
    }
    const { validations } = fields[fieldPath]
    const fieldValue = pathStr(fieldPath, values)

    const validationsArray = Array.isArray(validations)
      ? validations
      : toPairs(validations).map(([validationKey, validationSpec]) =>
          parseValidator(validationKey, validationSpec),
        )
    const failedValidation = validationsArray.find(
      (validator) => !validator.validate(fieldValue, values, fieldPath),
    )
    if (failedValidation) {
      this.showFieldErrors(
        fieldPath,
        typeof failedValidation.errorMessage === 'function'
          ? failedValidation.errorMessage(fieldValue, values, fieldPath)
          : failedValidation.errorMessage,
      )
      return false
    }
    this.clearFieldErrors(fieldPath)
    return true
  })

  /**
   * Store the error state of the field, which will be accessed by child components
   */
  showFieldErrors = (field, errorMessage) => {
    this.setState(
      assocPathStr(`errors.${field}`, {
        errorMessage,
        hasError: true,
      }),
    )
  }

  clearFieldErrors = (field) => {
    this.setState(assocPathStr(`errors.${field}`, { hasError: false }))
  }

  state = {
    initialValues: { ...(this.props.initialValues || {}) },
    values: { ...(this.props.initialValues || {}) },
    fields: {},
    errors: {},
    setFieldValue: this.setFieldValue,
    setValues: this.setValues,
    updateFieldValue: this.updateFieldValue,
    getFieldValue: this.getFieldValue,
    defineField: this.defineField,
    removeField: this.removeField,
    validateField: this.validateField,
    showingErrors: false,
    showErrorsOnBlur: this.props.showErrorsOnBlur,
  }

  /**
   * Validate all fields and return false if any error is found, true otherwise
   */
  validateForm = () => {
    const { fields } = this.state
    const results = Object.keys(fields).map((fieldPath) => this.validateField(fieldPath)(null))
    return !results.includes(false)
  }

  handleSubmit = async (event) => {
    const { clearOnSubmit, onSubmit } = this.props
    const { initialValues, values, showingErrors, fields } = this.state

    if (event) {
      event.preventDefault()
    }
    if (!this.validateForm()) {
      if (!showingErrors) {
        this.setState((prevState) => ({ ...prevState, showingErrors: true }))
      }
      return false
    }
    if (onSubmit) {
      // Only send the values from the fields defined in the form
      const formDefinedValues = Object.keys(fields).reduce(
        (acc, fieldPath) => assocPathStr(fieldPath, pathStr(fieldPath, values), acc),
        {},
      )
      await onSubmit(formDefinedValues)
    }

    if (clearOnSubmit) {
      this.setState({ values: initialValues })
    }
    return true
  }

  render() {
    const {
      children,
      classes,
      debug,
      id,
      title,
      link,
      className,
      topContent,
      formActions,
      elevated,
      withAddonManager,
      noEnter,
    } = this.props
    const inputs = children instanceof Function ? children(this.state) : children
    const { values } = this.state
    const contents = (
      <>
        {debug && <ValidatedFormDebug />}
        {!elevated ? (
          inputs
        ) : (
          <FormFieldSection title={title} link={link} className={className}>
            {topContent}
            {inputs}
          </FormFieldSection>
        )}
      </>
    )
    return (
      <form
        onSubmit={this.handleSubmit}
        className={classes.root}
        onKeyDown={noEnter ? handleKeyDown : null}
        id={id}
      >
        <ValidatedFormProvider value={this.state}>
          {/* {withAddonManager ? (
            <ClusterAddonManager>
              {contents}
              <AddonDetailCards
                values={values}
                setValues={this.setValues}
                setFieldValue={this.setFieldValue}
                defineField={this.defineField}
              />
            </ClusterAddonManager>
          ) : (
            contents
          )} */}
          {contents}
        </ValidatedFormProvider>
        {formActions ? (
          <div className={clsx('formActions', classes.formActions)}>{formActions}</div>
        ) : null}
      </form>
    )
  }
}

ValidatedForm.propTypes = {
  // When the form is successfully submitted, clear the form.
  // A common use case for this will be when we want to have the form stay on
  // the screen but each time the user makes a submit we add an item to an array
  // and allow them to add another.
  clearOnSubmit: PropTypes.bool,

  debug: PropTypes.bool,

  // Initial values
  initialValues: PropTypes.object,

  onSubmit: PropTypes.func,

  triggerSubmit: PropTypes.func,

  showErrorsOnBlur: PropTypes.bool,

  maxWidth: PropTypes.number,

  title: PropTypes.string,

  link: PropTypes.node,

  topContent: PropTypes.node,

  formActions: PropTypes.node,

  inputsWidth: PropTypes.number,

  // Wrap the children within a FormFieldCard
  elevated: PropTypes.bool,
  withAddonManager: PropTypes.bool,

  // This function will get called with context passed
  // through as argument after onSubmit is called
  afterSubmit: PropTypes.func,
}

ValidatedForm.defaultProps = {
  clearOnSubmit: false,
  debug: false,
  maxWidth: 932,
  elevated: true,
}

export default ValidatedForm
